import React, { PureComponent } from 'react';
import './Exercise.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import BreathDetection from './components/breath-detection/BreathDetection';
import { setCvReady } from '../../store/actions/cv';
import { cvSelectors } from '../../store/selectors/cv';
import loadFloeSdk from '../../common/utils/loadFloeSdk';

class Exercise extends PureComponent {
  componentDidMount() {
    const { cvReady, setCvReadyAction } = this.props;

    if (!cvReady) {
      loadFloeSdk(setCvReadyAction);
    }
  }

  render() {
    return (
      <div className="container exercise">
        <BreathDetection />
      </div>
    );
  }
}

Exercise.propTypes = {
  cvReady: PropTypes.bool.isRequired,
  setCvReadyAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setCvReadyAction: setCvReady,
}, dispatch);

const mapStateToProps = (state) => ({
  cvReady: cvSelectors.getCvReady(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Exercise);
