import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { API, Auth } from 'aws-amplify';

import { fetchSessions, fetchSessionsFailure } from 'app/store/actions/sessionsActions';
import { sessionsSelectors } from 'app/store/selectors/sessionsSelectors';
import { exerciseSelectors } from 'app/store/selectors/exerciseSelectors';
import { getGuides } from 'app/store/actions/exerciseActions';

import {
  longestStreakHandler,
  averageAccuracyHandler,
  graphHeightHandler,
  bpmHandler,
  groupedBreathsHandler,
} from 'app/common/utils/calculation';

import './ResultsContainer.scss';
import Session from './components/Session';
import Result from './components/Result';

class ResultContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      longestStreak: [0, 0],
      averageAccuracy: 0,
      graphHeighs: [],
      bpm: [],
      groupedBreaths: [],
      breaths: [],
      respInfo: [],
      activeSessionId: '',
      activeSessionDate: 0,
      guideId: '',
      isFetchingSession: true,
      isFirstSession: true,
    };

    this.breathsHandler = this.breathsHandler.bind(this);
    this.handleChangeSession = this.handleChangeSession.bind(this);
    this.fetchSessions = this.fetchSessions.bind(this);
  }

  async componentDidMount() {
    const { history, getGuidesAction } = this.props;
    await getGuidesAction();

    try {
      const user = await Auth.currentAuthenticatedUser();

      await this.fetchSessions(user.username);
      const { sessions } = this.props;

      this.setState({ isFetchingSession: false });

      if (sessions[0]) {
        this.setState({
          activeSessionId: sessions[0].SessionId,
          breaths: sessions[0].SessionData.breaths,
          respInfo: sessions[0].SessionData.respInfo,
          activeSessionDate: sessions[0].StartTime,
          guideId: sessions[0].SessionData.GuideId,
        }, () => {
          this.breathsHandler();
        });
      }
    } catch (error) {
      history.push('/');
    }
  }

  componentWillUnmount() {
    const { history: { location: { pathname } }, history } = this.props;

    if (pathname === '/questions-post-session') {
      history.push('/');
    }
  }

  async fetchSessions(username) {
    const { fetchSessionsAction, fetchSessionsFailureAction } = this.props;

    try {
      const sessions = await API.get('SessionAPI', '/sessions', {
        queryStringParameters: {
          user_id: username,
        },
      });
      const sortedSessions = sessions.data.sort((a, b) => b.StartTime - a.StartTime);
      fetchSessionsAction(sortedSessions);
    } catch (error) {
      fetchSessionsFailureAction(error);
    }
  }

  breathsHandler() {
    const { breaths, respInfo, guideId } = this.state;
    const { guides } = this.props;
    const guideObject = guides.find((guide) => guide.guideId === guideId);

    this.setState({
      longestStreak: longestStreakHandler(breaths, guideObject),
      averageAccuracy: averageAccuracyHandler(breaths, guideObject),
      graphHeighs: graphHeightHandler(respInfo, guideObject),
      bpm: bpmHandler(respInfo),
      groupedBreaths: groupedBreathsHandler(breaths),
    });
  }

  handleChangeSession(event) {
    const activeSessionId = event.currentTarget.dataset.id;
    const { sessions } = this.props;
    const activeSession = sessions.find((session) => session.SessionId === activeSessionId);

    this.setState({
      activeSessionId,
      breaths: activeSession.SessionData.breaths,
      respInfo: activeSession.SessionData.respInfo,
      activeSessionDate: activeSession.StartTime,
      isFirstSession: activeSessionId === sessions[0].SessionId ? true : false,
    }, () => {
      this.breathsHandler();
    });
  }

  render() {
    const {
      longestStreak,
      averageAccuracy,
      graphHeighs,
      bpm,
      groupedBreaths,
      activeSessionId,
      breaths,
      respInfo,
      activeSessionDate,
      isFetchingSession,
      isFirstSession,
    } = this.state;
    const { sessions, isActualSession, typeOfExercise } = this.props;
    const [startStreak, endStrek] = longestStreak;

    const averageAccuracyProcent = Math.round(averageAccuracy * 100);
    const exerciseTime = respInfo.length ? Math.round(respInfo[respInfo.length - 1].time) : 0;
    const longestStreakPeriod = endStrek - startStreak;
    const breathsPerMinute = breaths.length > 1
      ? Math.round(breaths.length / (exerciseTime / 60))
      : 0;

    const startMinutes = Math.floor(startStreak / 60);
    const endMinutes = Math.floor((endStrek) / 60);
    const startSeconds = startStreak - startMinutes * 60;
    const endSeconds = endStrek - endMinutes * 60;
    const startSecondsText = (startSeconds<10?'0':'').concat(startSeconds.toString());
    const startTime = `${startMinutes}:${startSecondsText || '00'}`;
    const endTime = `${endMinutes}:${endSeconds || '00'}`;
    const streak = [
      {
        id: 'streak',
        label: 'streak',
        value: longestStreakPeriod,
        tooltip: `${startTime} - ${endTime}`,
      },
      {
        id: 'exercise',
        label: 'exercise',
        value: exerciseTime - longestStreakPeriod,
      },
    ];

    return (
      <div className="container">
        <div className="results">
          <div className="results__navigation">
            <Session
              exerciseTime={exerciseTime}
              averageAccuracy={averageAccuracyProcent}
              sessions={sessions}
              activeSessionId={activeSessionId}
              onChangeSession={this.handleChangeSession}
              isActualSession={isActualSession}
            />
          </div>
          <div className="results__graphs">
            <Result
              graphHeighs={graphHeighs}
              bpm={bpm}
              groupedBreaths={groupedBreaths}
              breathsPerMinute={breathsPerMinute}
              streak={streak}
              breaths={breaths}
              longestStreakPeriod={longestStreakPeriod}
              averageAccuracyProcent={averageAccuracyProcent}
              activeSessionDate={activeSessionDate}
              startStreak={startStreak}
              isActualSession={isActualSession}
              isFetchingSession={isFetchingSession}
              isFirstSession={isFirstSession}
              typeOfExercise={typeOfExercise}
            />
          </div>
        </div>
      </div>
    );
  }
}

ResultContainer.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
  guides: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchSessionsAction: PropTypes.func.isRequired,
  fetchSessionsFailureAction: PropTypes.func.isRequired,
  getGuidesAction: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  isActualSession: PropTypes.bool.isRequired,
  typeOfExercise: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isActualSession: exerciseSelectors.getIsActualSession(state),
  sessions: sessionsSelectors.getSessionsData(state),
  guides: exerciseSelectors.getGuides(state),
  typeOfExercise: exerciseSelectors.getTypeOfExercise(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchSessionsAction: fetchSessions,
  fetchSessionsFailureAction: fetchSessionsFailure,
  getGuidesAction: getGuides,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ResultContainer));
