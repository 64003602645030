import {
  FETCH_SESSION,
  FETCH_SESSION_FAILURE,
  POST_SESSION_FAILURE,
} from '../actions/sessionsActions';

const initialState = {
  data: [],
  errors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SESSION:
      return {
        ...state,
        data: action.payload,
        errors: [],
      };

    case POST_SESSION_FAILURE:
    case FETCH_SESSION_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };

    default:
      return state;
  }
};
