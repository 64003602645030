import React from 'react';
import PropTypes from 'prop-types';
import womanImg from 'static/images/woman.svg';
import tableImg from 'static/images/table.svg';
import './StepThree.scss';

const StepThree = (props) => {
  const { replayInstruction, skipInstructions } = props;

  return (
    <div className="step-three">
      <div className="step-three-images">
        <img className="step-three-images__woman" src={womanImg} alt="woman on a chair" />
        <img className="step-three-images__table" src={tableImg} alt="table" />
        <div className="step-three-images__dots" />
      </div>
      <div className="step-three-text">
        <strong><p className="step-three-text--primary">Nothing between you and the camera</p></strong>
        <p className="step-three-text--secondary">
          Make sure your camera’s view is unimpeded and you are
          comfortably within the picture frame.
        </p>
      </div>
      <div className="step-three-buttons">
        <button className="button step-three-buttons__skip" type="button" onClick={skipInstructions}> Skip</button>
        <button className="step-three-buttons__replay" type="button" onClick={replayInstruction}>
          <span className="step-three-buttons__replay-icon" />
          Replay instructions
        </button>
      </div>
    </div>
  );
};

StepThree.propTypes = {
  replayInstruction: PropTypes.func.isRequired,
  skipInstructions: PropTypes.func.isRequired,
};

export default StepThree;
