import React from 'react';
import PropTypes from 'prop-types';
import womanImg from 'static/images/woman.svg';
import './StepOne.scss';

const StepOne = (props) => {
  const { skipInstructions } = props;
  return (
    <div className="step-one">
      <img className="step-one-img" src={womanImg} alt="woman on a chair" />
      <div className="step-one-text">
        <strong><p className="step-one-text--primary">Sit still and upright</p></strong>
        <p className="step-one-text--secondary">
          To get an accurate reading we need you to sit in a comfortable,
          still position while maintaining an upright posture.
        </p>
      </div>
      <button className="button step-one-button" type="button" onClick={skipInstructions}> Skip</button>
    </div>
  );
};

StepOne.propTypes = {
  skipInstructions: PropTypes.func.isRequired,
};

export default StepOne;
