const config = {
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_REDIRECT,
      redirectSignOut: process.env.REACT_APP_REDIRECT,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: 'SessionAPI',
        endpoint: process.env.REACT_APP_ENDPOINT_SESSION,
        region: 'eu-west-1',
      },
    ],
  },
};

export default config;
