import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { bindActionCreators } from 'redux';

import { userLogoutFailure } from 'app/store/actions/userActions';

import HeaderNavigation from './HeaderNavigation';

class HeaderNavigationContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openDropdown: false,
      attributes: {},
    };

    this.handleAvatarClick = this.handleAvatarClick.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
  }

  async componentDidMount() {
    const userInfo = await Auth.currentUserInfo();
    this.setState({
      attributes: userInfo.attributes,
    });
  }

  handleAvatarClick() {
    const { openDropdown } = this.state;

    this.setState({
      openDropdown: !openDropdown,
    });
  }

  async handleLogOut() {
    const { userLogoutFailureAction } = this.props;
    try {
      await Auth.signOut();
    } catch (error) {
      userLogoutFailureAction(error);
    }
  }

  render() {
    const { openDropdown, attributes } = this.state;

    return (
      <HeaderNavigation
        onAvatarClick={this.handleAvatarClick}
        onLogOut={this.handleLogOut}
        openDropdown={openDropdown}
        attributes={attributes}
      />
    );
  }
}

HeaderNavigationContainer.propTypes = {
  userLogoutFailureAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  userLogoutFailureAction: userLogoutFailure,
}, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(HeaderNavigationContainer);
