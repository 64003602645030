export default function drawSvgCurvePath(radius, cx, cy) {
  const h = radius;
  const w = 1.03125 * radius;
  const b = 0.625 * radius;

  let path = 'M';
  path += `${cx},${cy + h} `;
  path += 'C';
  path += `${cx + b},${cy + h} `;
  path += `${cx + w},${cy + w - h + b} `;
  path += `${cx + w},${cy + w - h} `;
  path += 'C';
  path += `${cx + w},${cy + w - h - b} `;
  path += `${cx + b},${cy + -h} `;
  path += `${cx},${cy - h} `;
  path += 'C';
  path += `${cx - b},${cy - h} `;
  path += `${cx - w},${cy + w - h - b} `;
  path += `${cx - w},${cy + w - h} `;
  path += 'C';
  path += `${cx - w},${cy + w - h + b} `;
  path += `${cx - b},${cy + h} `;
  path += `${cx},${cy + h} `;
  path += 'Z';
  return path;
}
