import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';

import Tooltip from '../components/Tooltip';

export default function GuideGraph({ data }) {
  return (
    <ResponsiveLine
      data={data}
      margin={{
        top: 0, right: 0, bottom: 0, left: 0,
      }}
      xScale={{ type: 'linear' }}
      yScale={{
        type: 'linear', stacked: false, min: 'auto', max: 'auto',
      }}
      curve="monotoneX"
      axisBottom={null}
      axisLeft={null}
      enableGridX={false}
      enableGridY={false}
      theme={{
        crosshair: {
          line: {
            strokeDasharray: '0',
          },
        },
      }}
      colors={['rgba(0, 228, 195, 0.8)', '#DEEAE9']}
      lineWidth={0}
      enablePoints={false}
      useMesh
      crosshairType="bottom"
      enableArea
      areaOpacity={1}
      tooltip={({ point }) => (
        <Tooltip time={Math.round(point.data.x)} />
      )}
    />
  );
}

GuideGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};
