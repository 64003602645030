import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { API, Auth } from 'aws-amplify';

import { postSessionsFailure } from 'app/store/actions/sessionsActions';
import { exerciseSelectors } from 'app/store/selectors/exerciseSelectors';
import { cvSelectors } from 'app/store/selectors/cv';
import { getQuestions, setPostSessionAnswers, actualSession } from 'app/store/actions/exerciseActions';

import { samplingData } from 'app/common/constants/data';
import QuestionForm from '../../common/components/question-form/QuestionForm';
import CircleIndicator from '../../common/components/circle-indicator/CircleIndicator';
import SdkApi from '../../common/utils/sdk_api';

import './QuestionsPostSession.scss';

class QuestionsPostSession extends PureComponent {
  constructor() {
    super();

    this.state = {
      respInfoResults: [],
      answers: [],
      currentQuestionIndex: 0,
      nextButtonDisabled: false,
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.redirectToResult = this.redirectToResult.bind(this);
  }

  componentDidMount() {
    const {
      getQuestionsAction,
      typeOfExercise,
      history,
      respInfo,
    } = this.props;
    if (!typeOfExercise) {
      history.push('/');
    } else {
      getQuestionsAction();
      // saving results, becouse respInfo is still changing
      this.setState({ respInfoResults: respInfo });
    }
  }

  componentWillUnmount() {
    const { history: { location: { pathname } } } = this.props;

    if (!(pathname === '/exercise')) {
      SdkApi.stop();
    }
  }

  async uploadSessionToDB() {
    const {
      breaths,
      history,
      preSessionAnswers,
      postSessionAnswers,
      postSessionsFailureAction,
      guide,
      actualSessionAction,
    } = this.props;
    const { respInfoResults } = this.state;

    const startTime = await Date.now();
    const user = await Auth.currentAuthenticatedUser();
    const { username } = user;
    const respInfoSampled = [];

    for (let i = 0; i < respInfoResults.length; i += samplingData) {
      respInfoSampled.push(respInfoResults[i]);
    }

    const postBody = {
      body: {
        SessionId: `${username}_${startTime}`,
        UserId: username,
        StartTime: startTime,
        SessionData: {
          respInfo: respInfoSampled,
          breaths,
          preSessionAnswers,
          postSessionAnswers,
          GuideId: guide.guideId,
        },
      },
    };

    try {
      await API.post('SessionAPI', '/sessions', postBody);
      actualSessionAction();
      this.redirectToResult();
    } catch (error) {
      postSessionsFailureAction(error);
      history.push('/');
    }
  }

  redirectToResult() {
    const { history } = this.props;
    history.push('/result');
  }

  handleBack() {
    const { currentQuestionIndex } = this.state;

    if (currentQuestionIndex > 0) {
      this.setState((prevState) => {
        const newAnswers = [...prevState.answers];
        newAnswers.pop();
        return {
          answers: newAnswers,
          currentQuestionIndex: prevState.currentQuestionIndex - 1,
        };
      });
    }
  }

  handleNext(answer) {
    const { questions, setPostSessionAnswersAction } = this.props;
    const { currentQuestionIndex } = this.state;

    if (currentQuestionIndex === questions.length - 1) {
      this.setState((prevState) => ({
        nextButtonDisabled: true,
        answers: [...prevState.answers, answer],
      }), async () => {
        const { answers } = this.state;
        await setPostSessionAnswersAction(answers);
        this.uploadSessionToDB();
      });
    } else {
      this.setState((prevState) => ({
        answers: [...prevState.answers, answer],
        currentQuestionIndex: prevState.currentQuestionIndex + 1,
      }));
    }
  }

  render() {
    const { questions, graphHeight, diffFromCenterX } = this.props;
    const { currentQuestionIndex, nextButtonDisabled } = this.state;

    return (
      <div className="container questions-post-session">
        <div className="circle-container">
          <CircleIndicator graphHeight={graphHeight} diffFromCenterX={diffFromCenterX} />
        </div>
        {questions
          && (
            <div className="questions-post-session__questions">
              <QuestionForm
                questionObj={questions[currentQuestionIndex]}
                backFn={this.handleBack}
                nextFn={this.handleNext}
                backButtonDisabled={currentQuestionIndex === 0}
                nextButtonDisabled={nextButtonDisabled}
              />
            </div>
          )}
      </div>
    );
  }
}

QuestionsPostSession.propTypes = {
  graphHeight: PropTypes.number.isRequired,
  diffFromCenterX: PropTypes.number.isRequired,
  getQuestionsAction: PropTypes.func.isRequired,
  setPostSessionAnswersAction: PropTypes.func.isRequired,
  postSessionsFailureAction: PropTypes.func.isRequired,
  actualSessionAction: PropTypes.func.isRequired,
  preSessionAnswers: PropTypes.arrayOf(PropTypes.object).isRequired,
  postSessionAnswers: PropTypes.arrayOf(PropTypes.object).isRequired,
  respInfo: PropTypes.arrayOf(PropTypes.object).isRequired,
  breaths: PropTypes.arrayOf(PropTypes.array).isRequired,
  typeOfExercise: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      questionUUID: PropTypes.string,
      question: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          index: PropTypes.number,
          displayText: PropTypes.string,
        }),
      ),
    }),
  ),
  guide: PropTypes.shape({
    name: PropTypes.string,
    guideId: PropTypes.string,
    category: PropTypes.string,
    maxTime: PropTypes.number,
    exerciseLoop: PropTypes.arrayOf(PropTypes.shape({
      stepIndex: PropTypes.number,
      displayName: PropTypes.string,
      duration: PropTypes.number,
      scaleFactor: PropTypes.number,
      increment: PropTypes.number,
      initialGraphHeight: PropTypes.number,
      targetGraphHeight: PropTypes.number,
    })),
  }),
  history: ReactRouterPropTypes.history.isRequired,
};

QuestionsPostSession.defaultProps = {
  questions: null,
  typeOfExercise: null,
  guide: null,
};

const mapStateToProps = (state) => ({
  questions: exerciseSelectors.getPostSessionQuestions(state),
  preSessionAnswers: exerciseSelectors.getPreSessionAnswers(state),
  postSessionAnswers: exerciseSelectors.getPostSessionAnswers(state),
  respInfo: cvSelectors.getRespInfo(state),
  breaths: cvSelectors.getBreaths(state),
  guide: exerciseSelectors.getGuide(state),
  typeOfExercise: exerciseSelectors.getTypeOfExercise(state),
  graphHeight: cvSelectors.getLastGraphHeight(state),
  diffFromCenterX: cvSelectors.getDiffFromCenterX(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getQuestionsAction: getQuestions,
  setPostSessionAnswersAction: setPostSessionAnswers,
  postSessionsFailureAction: postSessionsFailure,
  actualSessionAction: actualSession,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuestionsPostSession));
