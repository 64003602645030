const PREFIX = '[SESSION]';
export const FETCH_SESSION = `${PREFIX} FETCH_SESSION`;
export const FETCH_SESSION_FAILURE = `${PREFIX} FETCH_SESSION_FAILURE`;
export const POST_SESSION_FAILURE = `${PREFIX} POST_SESSION_FAILURE`;

export const fetchSessions = (payload) => ({
  type: FETCH_SESSION,
  payload,
});

export const fetchSessionsFailure = (payload) => ({
  type: FETCH_SESSION_FAILURE,
  payload,
});

export const postSessionsFailure = (payload) => ({
  type: POST_SESSION_FAILURE,
  payload,
});
