import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';

import BreathsTooltip from '../components/BreathsTooltip';

export default function BreathsGraph({ data }) {
  return (
    <ResponsiveBar
      data={data}
      keys={['breath']}
      indexBy="time"
      margin={{
        top: 10, right: 0, bottom: 0, left: 0,
      }}
      padding={0}
      borderWidth={7}
      borderColor="#f9f9f9"
      colors="#00E4C3"
      borderRadius={4}
      axisBottom={null}
      axisLeft={null}
      theme={{
        tooltip: {
          container: {
            padding: 0,
            borderRadius: '20px',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        },
      }}
      enableGridY={false}
      enableLabel={false}
      onMouseEnter={(_data, event) => {
        event.target.style.fill = '#393D3C';
      }}
      onMouseLeave={(_data, event) => { 
        event.target.style.fill = '#00E4C3';
      }}
      tooltip={(point) => (
        <BreathsTooltip time={point.data.time} />
      )}
      animate
      motionStiffness={90}
      motionDamping={15}
    />
  );
}

BreathsGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};
