import { combineReducers } from 'redux';
import cv from './cv';
import exercise from './exercise';
import sessions from './sessions';
import user from './user';

const rootReducer = combineReducers({
  cv,
  exercise,
  sessions,
  user,
});

export default rootReducer;
