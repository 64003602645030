import React, { PureComponent } from 'react';
import {
  Authenticator,
  SignIn,
  SignUp,
  Greetings,
  ConfirmSignUp,
  ForgotPassword,
} from 'aws-amplify-react';

import CustomSignIn from './app/components/auth/CustomSignIn';
import CustomSignUp from './app/components/auth/CustomSignUp';
import CustomConfirmSignUp from './app/components/auth/CustomConfirmSignUp';
import CustomForgotPassword from './app/components/auth/CustomForgotPassword';
import App from './App';

class Auth extends PureComponent {
  render() {
    return (
      <Authenticator hide={[SignIn, SignUp, Greetings, ConfirmSignUp, ForgotPassword]}>
        <CustomSignIn />
        <CustomSignUp />
        <CustomConfirmSignUp />
        <CustomForgotPassword />
        <App />
      </Authenticator>
    );
  }
}

export default Auth;
