import React from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';

import StreakTooltip from '../components/StreakTooltip';


export default function StreakGraph({ data, startStreak }) {
  if (data.length) {
    const startAngle = (startStreak && data[1].value) ? (startStreak / data[1].value) * 360 : 0;
    const endAngle = startAngle ? startAngle + 360 : 360;

    return (
      <ResponsivePie
        data={data}
        margin={{
          top: 0, right: 0, bottom: 0, left: 0,
        }}
        innerRadius={0.8}
        padAngle={data[0].value ? 2 : 0}
        startAngle={startAngle}
        endAngle={endAngle}
        cornerRadius={2}
        colors={['#00E4C3', '#DEEAE9']}
        enableRadialLabels={false}
        enableSlicesLabels={false}
        theme={{
          tooltip: {
            container: {
              backgroundColor: 'transparent',
              padding: 0,
              boxShadow: 'none',
              borderRadius: '20px',
            },
          },
        }}
        animate
        onMouseEnter={(_data, event) => {
          if (_data.label === 'streak') { event.target.style.fill = '#393D3C'; }
        }}
        onMouseLeave={(_data, event) => {
          if (_data.label === 'streak') { event.target.style.fill = '#00E4C3'; }
        }}
        motionStiffness={90}
        motionDamping={15}
        tooltip={({ id, tooltip }) => id === 'streak' && <StreakTooltip time={tooltip} />}
      />
    );
  }
}

StreakGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  startStreak: PropTypes.number.isRequired,
};
