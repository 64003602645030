import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import './styles/main.scss';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import Dashboard from './app/components/dashboard';
import Settings from './app/components/settings';
import Instruction from './app/components/instruction';
import Calibration from './app/components/calibration';
import Exercise from './app/components/exercise';
import QuestionsPreSession from './app/components/questions-pre-session';
import QuestionsPostSession from './app/components/questions-post-session';
import Result from './app/components/result';
import Header from './app/common/header';
import configureStore from './app/store/store';

export const store = configureStore();

function App({ authState }) {
  if (authState === 'signedIn') {
    return (
      <div className="App">
        <Provider store={store}>
          <Router>
            <Header />
            <video id="video" className="video"><track kind="captions" /></video>
            <Switch>
              <Route exact path="/">
                <Dashboard />
              </Route>
              <Route path="/instruction">
                <Instruction />
              </Route>
              <Route path="/questions-pre-session">
                <QuestionsPreSession />
              </Route>
              <Route path="/questions-post-session">
                <QuestionsPostSession />
              </Route>
              <Route path="/calibration">
                <Calibration />
              </Route>
              <Route path="/exercise">
                <Exercise />
              </Route>
              <Route path="/result">
                <Result />
              </Route>
              <Route path="/settings">
                <Settings />
              </Route>
            </Switch>
          </Router>
        </Provider>
      </div>
    );
  }

  return null;
}

App.propTypes = {
  authState: PropTypes.string,
};

App.defaultProps = {
  authState: '',
};

export default App;
