import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import QuestionForm from '../../common/components/question-form/QuestionForm';
import { exerciseSelectors } from '../../store/selectors/exerciseSelectors';
import { cvSelectors } from '../../store/selectors/cv';
import { getQuestions, setPreSessionAnswers } from '../../store/actions/exerciseActions';
import CircleIndicator from '../../common/components/circle-indicator/CircleIndicator';
import ProgressDots from '../../common/components/progress-dots/ProgressDots';
import './QuestionsPreSession.scss';
import SdkApi from '../../common/utils/sdk_api';

class QuestionsPreSession extends PureComponent {
  constructor() {
    super();

    this.state = {
      answers: [],
      currentQuestionIndex: 0,
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.redirectToCalibration = this.redirectToCalibration.bind(this);
    this.redirectToExercise = this.redirectToExercise.bind(this);
  }

  componentDidMount() {
    const { getQuestionsAction, typeOfExercise, history } = this.props;
    if (!typeOfExercise) {
      history.push('/');
    } else {
      getQuestionsAction();
    }
  }

  componentWillUnmount() {
    const { history: { location: { pathname } } } = this.props;

    if (!(pathname === '/exercise' || pathname === '/calibration')) {
      SdkApi.stop();
    }
  }

  redirectToCalibration() {
    const { history } = this.props;
    history.push('/calibration');
  }

  redirectToExercise() {
    const { history } = this.props;
    history.push('/exercise');
  }

  handleBack() {
    const { currentQuestionIndex } = this.state;


    if (currentQuestionIndex === 0) {
      this.redirectToCalibration();
    } else {
      this.setState((prevState) => {
        const newAnswers = [...prevState.answers];
        newAnswers.pop();
        return {
          answers: newAnswers,
          currentQuestionIndex: prevState.currentQuestionIndex - 1,
        };
      });
    }
  }

  handleNext(answer) {
    const { questions, setPreSessionAnswersAction } = this.props;
    const { currentQuestionIndex } = this.state;

    if (currentQuestionIndex === questions.length - 1) {
      this.setState((prevState) => ({
        answers: [...prevState.answers, answer],
      }), () => {
        const { answers } = this.state;
        setPreSessionAnswersAction(answers);
        this.redirectToExercise();
      });
    } else {
      this.setState((prevState) => ({
        answers: [...prevState.answers, answer],
        currentQuestionIndex: prevState.currentQuestionIndex + 1,
      }));
    }
  }

  render() {
    const { questions, graphHeight, diffFromCenterX } = this.props;
    const { currentQuestionIndex } = this.state;

    return (
      <div className="container questions-pre-session">
        <div className="circle-container">
          <CircleIndicator graphHeight={graphHeight} diffFromCenterX={diffFromCenterX} />
        </div>
        {questions
          && (
            <div className="questions-pre-session__questions">
              <QuestionForm
                questionObj={questions[currentQuestionIndex]}
                backFn={this.handleBack}
                nextFn={this.handleNext}
              />
            </div>
          )}
        <div className="progress-dots-container">
          <ProgressDots progressDotsNumber={3} />
        </div>
      </div>
    );
  }
}

QuestionsPreSession.propTypes = {
  graphHeight: PropTypes.number.isRequired,
  diffFromCenterX: PropTypes.number.isRequired,
  getQuestionsAction: PropTypes.func.isRequired,
  setPreSessionAnswersAction: PropTypes.func.isRequired,
  typeOfExercise: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      questionUUID: PropTypes.string,
      question: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          index: PropTypes.number,
          displayText: PropTypes.string,
        }),
      ),
    }),
  ),
  history: ReactRouterPropTypes.history.isRequired,
};

QuestionsPreSession.defaultProps = {
  questions: null,
  typeOfExercise: null,
};

const mapStateToProps = (state) => ({
  questions: exerciseSelectors.getPreSessionQuestions(state),
  typeOfExercise: exerciseSelectors.getTypeOfExercise(state),
  graphHeight: cvSelectors.getLastGraphHeight(state),
  diffFromCenterX: cvSelectors.getDiffFromCenterX(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getQuestionsAction: getQuestions,
  setPreSessionAnswersAction: setPreSessionAnswers,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuestionsPreSession));
