import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { exerciseSelectors } from 'app/store/selectors/exerciseSelectors';
import { connect } from 'react-redux';
import StepOne from './components/step-one/StepOne';
import StepTwo from './components/step-two/StepTwo';
import StepThree from './components/step-three/StepThree';
import ProgressDots from '../../common/components/progress-dots/ProgressDots';

const steps = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
};

class Instruction extends PureComponent {
  constructor() {
    super();

    this.state = {
      step: steps.FIRST,
    };

    this.nextStep = this.nextStep.bind(this);
    this.replayInstruction = this.replayInstruction.bind(this);
    this.redirectToCalibration = this.redirectToCalibration.bind(this);
  }

  componentDidMount() {
    const { typeOfExercise, history } = this.props;
    if (!typeOfExercise) {
      history.push('/');
    } else {
      this.instructionsInterval = setInterval(() => {
        const { step } = this.state;

        if (step < steps.THIRD) {
          this.nextStep();
        } else {
          this.redirectToCalibration();
        }
      }, 5000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.instructionsInterval);
  }

  nextStep() {
    this.setState((prevState) => ({ step: prevState.step + 1 }));
  }

  redirectToCalibration() {
    const { history } = this.props;
    history.push('/calibration');
  }

  replayInstruction() {
    this.setState({ step: steps.FIRST });
    clearInterval(this.instructionsInterval);
    this.instructionsInterval = setInterval(() => this.nextStep(), 4000);
  }

  render() {
    const { step } = this.state;
    return (
      <div className="container">
        {step === steps.FIRST && <StepOne skipInstructions={this.redirectToCalibration} />}
        {step === steps.SECOND && <StepTwo skipInstructions={this.redirectToCalibration} />}
        {step === steps.THIRD && <StepThree replayInstruction={this.replayInstruction} skipInstructions={this.redirectToCalibration} />}
        <div className="progress-dots-container">
          <ProgressDots progressDotsNumber={1} />
        </div>
      </div>
    );
  }
}

Instruction.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  typeOfExercise: PropTypes.string,
};

Instruction.defaultProps = {
  typeOfExercise: null,
};

const mapStateToProps = (state) => ({
  typeOfExercise: exerciseSelectors.getTypeOfExercise(state),
});

export default connect(mapStateToProps)(withRouter(Instruction));
