/* eslint-disable import/prefer-default-export */
export const questionsMock = {
  'pre-exercise-questions': {
    QuestionnaireId: '26be1994-f2c6-4034-a759-5e9b45f5238a',
    QuestionnaireName: 'FeelingMultiEmotionPre',
    questionnaire: [
      {
        questionUUID: 'aa083692-f931-47fa-8b00-cadaf1cff38a',
        question: 'How are you feeling?',
        options: [
          {
            index: 0,
            displayText: 'Happy',
          },
          {
            index: 1,
            displayText: 'Calm',
          },
          {
            index: 2,
            displayText: 'Anxious',
          },
          {
            index: 3,
            displayText: 'Stressed',
          },
          {
            index: 4,
            displayText: 'Tired',
          },
        ],
      },
    ],
  },
  'post-exercise-questions': {
    QuestionnaireId: '26be1994-f2c6-4034-a759-5e9b45f5238a',
    QuestionnaireName: 'FeelingMultiEmotionPost',
    questionnaire: [
      {
        questionUUID: '21231994-f2c6-4034-a759-5e9b45f5238a',
        question: 'How are you feeling?',
        options: [
          {
            index: 0,
            displayText: 'Happy',
          },
          {
            index: 1,
            displayText: 'Calm',
          },
          {
            index: 2,
            displayText: 'Anxious',
          },
          {
            index: 3,
            displayText: 'Stressed',
          },
          {
            index: 4,
            displayText: 'Tired',
          },
        ],
      },
    ],
  },
};
