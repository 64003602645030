import React from 'react';
import PropTypes from 'prop-types';

function BreathsTooltip({ time }) {
  return (
    <div
      style={{
        position: 'absolute',
        right: 'calc(50% - 50px)',
        width: '80px',
        top: '-80px',
        background: '#393D3C',
        borderRadius: '20px',
        color: 'white',
        padding: '9px 6px',
        fontWeight: '500',
      }}
    >
      <div>{time}</div>
      <div
        style={{
          position: 'absolute',
          bottom: '-3px',
          left: 'calc(50% - 3px)',
          width: '6px',
          height: '6px',
          background: '#393D3C',
          transform: 'rotate(45deg)',
          boxShadow: '1px -1px 2px 0px rgba(0, 0, 0, 0.08)',
        }} />
    </div>
  );
}

BreathsTooltip.propTypes = {
  time: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default BreathsTooltip;
