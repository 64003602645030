import React from 'react';
import PropTypes from 'prop-types';
import './CircleIndicator.scss';
import drawSvgCurvePath from '../../utils/drawSvgCurvePath';

/**
 * This is a default circle for expanding an shrunking
 */
const CircleIndicator = (props) => {
  const drawCircle = (graphHeight, diffFromCenterX) => {
    // These are specific values for the indicator
    const radius = Math.min((graphHeight + 2) * 12);
    const r = (radius + 20) * 1.1;
    const curve = drawSvgCurvePath(radius + 20, r, r);
    const insideCurve = drawSvgCurvePath(radius + 17, r, r);
    let marginLeft = 0;

    marginLeft = diffFromCenterX * 3;

    return (
      <svg
        expanded="true"
        width={2 * r}
        height={2 * r}
        style={({ marginLeft })}
        className="svg-container"
      >
        <path className="indicator" d={curve} />
        <path className="indicator indicator--inside" d={insideCurve} />
      </svg>
    );
  };
  const {
    graphHeight,
    diffFromCenterX,
  } = props;

  return (
    <div className="circle-indicator">
      {drawCircle(graphHeight, diffFromCenterX)}
    </div>
  );
};

CircleIndicator.propTypes = {
  graphHeight: PropTypes.number,
  diffFromCenterX: PropTypes.number,
};

CircleIndicator.defaultProps = {
  graphHeight: 2,
  diffFromCenterX: 0,
};

export default CircleIndicator;
