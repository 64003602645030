import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import HeaderExerciseContainer from './components/header-exercise';
import HeaderNavigationContainer from './components/header-navigation';
import './Header.scss';

function Header({ location, history }) {
  const exercisePath = ['/exercise', '/instruction', '/calibration', '/questions-pre-session', '/questions-post-session'];
  const isExercisePath = exercisePath.includes(location.pathname);

  return (
    <header className="page-header">
      {
        isExercisePath
          ? (
            <HeaderExerciseContainer path={location.pathname} history={history} />
          )
          : (
            <HeaderNavigationContainer />
          )
      }
    </header>
  );
}

Header.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

export default withRouter(Header);
