
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { bindActionCreators } from 'redux';

import { exerciseSelectors } from 'app/store/selectors/exerciseSelectors';
import { cvSelectors } from 'app/store/selectors/cv';
import { postSessionsFailure } from 'app/store/actions/sessionsActions';

import HeaderExercise from './HeaderExercise';

class HeaderExerciseContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.handleEndSessionClick = this.handleEndSessionClick.bind(this);
  }

  handleEndSessionClick() {
    const { history, path } = this.props;
    if (path === '/exercise') {
      history.push('/questions-post-session');
    } else {
      history.push('/');
    }
  }

  render() {
    const { typeOfExercise, path, isCameraRunning } = this.props;

    return (
      <HeaderExercise
        typeOfExercise={typeOfExercise}
        path={path}
        isCameraRunning={isCameraRunning}
        onEndSessionClick={this.handleEndSessionClick}
      />
    );
  }
}

HeaderExerciseContainer.propTypes = {
  typeOfExercise: PropTypes.string.isRequired,
  isCameraRunning: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

const mapStateToProps = (state) => ({
  typeOfExercise: exerciseSelectors.getTypeOfExercise(state) || 'Focus',
  isCameraRunning: cvSelectors.getCameraRunning(state),
  respInfo: cvSelectors.getRespInfo(state),
  breaths: cvSelectors.getBreaths(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  postSessionsFailureAction: postSessionsFailure,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderExerciseContainer);
