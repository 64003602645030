import { store } from '../../../App';
import {
  addRespInfo,
  setBreaths,
  setCameraOff,
  setCameraOn,
  setCameraPending,
} from '../../store/actions/cv';
import { cvSelectors } from '../../store/selectors/cv';
import { videoResolution } from '../constants/data';

class SdkApi {
  constructor() {
    this.faceCascadeFileName = 'haarcascade_frontalface_default.xml';
    this.eyeCascadeFileName = 'haarcascade_eye.xml';
    this.samplePerSecond = 10;
    this.ready = false;
    this.navigator = window.navigator;
    this.videoContainer = null;
    this.breathingRates = [];
    this.cascadeFileExists = false;
  }

  /**
   * Starts camera with and breath detection.
   * Results are in store.
   * @memberof SdkApi
   */
  run = () => {
    if (this.detector) {
      this.detector.reset();
    }

    this.startCamera().then(() => {
      this.onVideoStarted();
    });
  }

  /**
   * Stops breath detection.
   * @memberof SdkApi
   */
  stop = () => {
    this.stopCamera();
    if (this.detector) {
      store.dispatch(setBreaths(this.calculateBreaths()));
    }
  }

  /**
   * Resets sdk
   * @memberof SdkApi
   */
  reset = () => {
    if (this.detector) {
      this.detector.reset();
    }
  }

  /**
   * Calculate breaths array, and send it to store.
   * @memberof SdkApi
   */
  calculateBreaths = () => {
    const breathStarts = this.detector.getBreathStarts();
    const breathEnds = this.detector.getBreathEnds();

    const breaths = [];

    for (let i = 0; i < breathStarts.size(); i++) {
      breaths.push([breathStarts.get(i), breathEnds.get(i)]);
    }

    store.dispatch(setBreaths(breaths));
  }

  startCamera = async () => {
    store.dispatch(setCameraPending());
    if (!cvSelectors.getCameraRunning(store.getState())) {
      this.videoContainer = document.getElementById('video');
      try {
        const stream = await window.navigator.mediaDevices.getUserMedia({
          video: true,
          audio: false,
        });
        store.dispatch(setCameraOn());
        this.videoContainer.srcObject = stream;
        this.videoContainer.play();
        this.videoStream = stream;
      } catch (err) {
        console.log(err);
      }
    }
  };

  stopCamera = () => {
    if (cvSelectors.getCameraRunning(store.getState())) {
      this.videoContainer.pause();
      this.videoContainer.srcObject = null;
      this.videoStream.getVideoTracks()[0].stop();
      store.dispatch(setCameraOff());
    }
  };

  // Setup opencv
  onVideoStarted = () => {
    this.videoContainer.width = videoResolution.width;
    this.videoContainer.height = videoResolution.height;

    this.cap = new window.cv.VideoCapture(this.videoContainer);
    this.src = new window.cv.Mat(
      this.videoContainer.height,
      this.videoContainer.width,
      window.cv.CV_8UC4,
    );

    // load pre-trained classifiers
    if (!this.cascadeFileExists) {
      this.detector = new window.cv.FloeDetector();
      this.createFileFromUrl(this.faceCascadeFileName, this.faceCascadeFileName, () => {
        this.detector.loadFaceCascade(this.faceCascadeFileName);

        this.createFileFromUrl(this.eyeCascadeFileName, this.eyeCascadeFileName, () => {
          this.detector.loadEyeCascade(this.eyeCascadeFileName);
          this.cascadeFileExists = true;
          requestAnimationFrame(this.processVideo);
        });
      });
    } else {
      requestAnimationFrame(this.processVideo);
    }
  }

  // Send each frame to opencv and retrieve the result
  processVideo = () => {
    if (cvSelectors.getCameraRunning(store.getState())) {
      this.cap.read(this.src);
      const rrInfo = this.detector.detectRespiratoryRate(this.src);

      const {
        breathCount,
        face,
        graphHeight,
        index,
        inhaleExhaleTime,
        respRate,
        time,
        x,
        y,
      } = rrInfo;

      store.dispatch(addRespInfo({
        breathCount,
        face,
        graphHeight,
        index,
        inhaleExhaleTime,
        respRate,
        time,
        x,
        y,
      }));

      window.requestAnimationFrame(this.processVideo);
    }
  };

  // Function to get file from URL
  // TODO: If this file is going to remain local, then import it directly from disk.
  createFileFromUrl = (path, url, callback) => {
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'arraybuffer';
    request.onload = () => {
      if (request.readyState === 4) {
        if (request.status === 200) {
          const data = new Uint8Array(request.response);
          window.cv.FS_createDataFile('/', path, data, true, false, false);
          callback();
        } else {
          console.log(`Failed to load ${url} status: ${request.status}`);
        }
      }
    };
    request.send();
  };
}

export default new SdkApi();
