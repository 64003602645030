import React from 'react';
import PropTypes from 'prop-types';
import './CalibrationCircle.scss';
import drawSvgCurvePath from '../../../../common/utils/drawSvgCurvePath';

/**
 * This is a customized circle with shadows and loading animation
 */
const CalibrationCircle = (props) => {
  const drawCircle = (graphHeight, shine, percent) => {
    // These are specific values for the indicator
    const radius = Math.min((graphHeight + 2) * 12);
    const r = (radius + 20) * 1.1;
    const curve = drawSvgCurvePath(radius + 20, r, r);

    return (
      <div>
        <svg
          className={shine ? 'svg shadow shine' : 'svg shadow'}
          expanded="true"
          width={2 * r}
          height={2 * r}
        >
          <path className="back-oval" d={curve} />
        </svg>
        <svg
          className="svg"
          expanded="true"
          width={2 * r}
          height={2 * r}
        >
          <defs>
            <mask id="mask">
              <circle
                className="mask"
                cx="50%"
                cy="50%"
                r="57"
                strokeDashoffset={(percent / 100) * 358}
              />
            </mask>
          </defs>
          <g mask="url(#mask)">
            <path className="front-oval" d={curve} />
          </g>
        </svg>
      </div>
    );
  };

  const {
    graphHeight,
    shine,
    percentFulfilled,
  } = props;

  return (
    <div className="calibration-circle">
      {drawCircle(graphHeight, shine, percentFulfilled)}
    </div>
  );
};

CalibrationCircle.propTypes = {
  graphHeight: PropTypes.number,
  shine: PropTypes.bool,
  percentFulfilled: PropTypes.number,
};

CalibrationCircle.defaultProps = {
  graphHeight: 2,
  shine: false,
  percentFulfilled: 0,
};

export default CalibrationCircle;
