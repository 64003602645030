import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import GuideGraph from 'app/common/graphs/GuideGraph';
import calmIcon from 'static/icons/icon_dashboard_calm.svg';
import creativityIcon from 'static/icons/icon_dashboard_creativity.svg';
import focusIcon from 'static/icons/icon_dashboard_focus.svg';

import './Dashboard.scss';

class Dashboard extends PureComponent {
  render() {
    const {
      onStartSession,
      averageAccuracyProcent,
      graphHeighs,
      isFetchingSession,
      isEmptyState,
      firstName,
    } = this.props;

    return (
      <div className="container">
        <div className="dashboard">
          <h2 className="dashboard__title">Welcome {firstName}</h2>
          {
            isFetchingSession
            && (
              <div className="dashboard__loading">
                <span className="dashboard__title">Loading...</span>
              </div>
            )
          }
          {
            !isFetchingSession && !isEmptyState
            && (
              <>
                <div className="dashboard__session-info">
                  <span className="dashboard__text">Last session</span>
                  <div>
                    <p className="dashboard__text">Session accuracy</p>
                    <span className="dashboard__value">{averageAccuracyProcent}%</span>
                  </div>
                </div>
                <div className="dashboard__guide-graph">
                  <GuideGraph data={graphHeighs} />
                </div>
              </>
            )
          }
          {
            isEmptyState
            && (
              <div className="dashboard__empty-state">
                <div>
                  <h3 className="dashboard__empty-headline">{"Welcome to Floe's Beta release"}</h3>
                  <p className="dashboard__empty-description">
                    {"Floe's responsive breathing exercises help you access Focus, Creativity or Calm. Each session is 90 seconds - Enjoy!"}
                  </p>
                </div>
                {/*<div className="dashboard__get-started">
                  <button
                    type="button"
                    className="button"
                    onClick={onStartSession}
                    data-type="Focus"
                  >
                    Get started
                  </button>
                </div>*/}
              </div>
            )
          }
          <span className="dashboard__text">Start a session</span>
          <div className="dashboard__sessions-container">
            <div className="dashboard__session">
              <img src={focusIcon} alt="Focus" className="dashboard__icon" />
              <h3 className="dashboard__session-type">Focus</h3>
              <p className="dashboard__description">Achieve a state of focus for tasks that require deeper concentration</p>
              <button
                type="button"
                className="button"
                onClick={onStartSession}
                data-type="Focus"
              >
                Start
              </button>
            </div>
            <div className="dashboard__session">
              <img src={creativityIcon} alt="Creativity" className="dashboard__icon" />
              <h3 className="dashboard__session-type">Creativity</h3>
              <p className="dashboard__description">With no voice guide, just a breath count, use while trying to think creatively.</p>
              <button
                type="button"
                className="button"
                onClick={onStartSession}
                data-type="Creativity"
              >
                Start
              </button>
            </div>
            <div className="dashboard__session">
              <img src={calmIcon} alt="Calm" className="dashboard__icon" />
              <h3 className="dashboard__session-type">Calm</h3>
              <p className="dashboard__description">Activate internal calm and reduce stress and relieve anxiety.</p>
              <button
                type="button"
                className="button"
                onClick={onStartSession}
                data-type="Calm"
              >
                Start
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  graphHeighs: PropTypes.arrayOf(PropTypes.object).isRequired,
  averageAccuracyProcent: PropTypes.number.isRequired,
  onStartSession: PropTypes.func.isRequired,
  isFetchingSession: PropTypes.bool.isRequired,
  isEmptyState: PropTypes.bool.isRequired,
  firstName: PropTypes.string,
};

Dashboard.defaultProps = {
  firstName: null,
};

export default Dashboard;
