/* eslint-disable import/prefer-default-export */
export const guidesMock = [
  {
    guideId: '116651ac-b177-4a63-b392-054abf0e466d',
    category: 'Focus',
    name: 'Box Exercise',
    preExerciseQuestionnaireId: '26be1994-f2c6-4034-a759-5e9b45f5238a',
    postExerciseQuestionnaireId: '26be1994-f2c6-4034-a759-5e9b45f5238a',
    maxTime: 90,
    backgroundAudioFileName: 'tamara.mp3',
    exerciseLoop: [
      {
        stepIndex: 0,
        displayName: 'inhale',
        duration: 3,
        scaleFactor: 1,
        increment: 0,
        initialGraphHeight: 0,
        targetGraphHeight: 5,
        audioFileName: 'inhale_tone.mp3',
      },
      {
        stepIndex: 1,
        displayName: 'hold',
        duration: 3,
        scaleFactor: 1,
        increment: 0,
        initialGraphHeight: 5,
        targetGraphHeight: 5,
      },
      {
        stepIndex: 2,
        displayName: 'exhale',
        duration: 3,
        scaleFactor: 1,
        increment: 0,
        initialGraphHeight: 5,
        targetGraphHeight: 0,
        audioFileName: 'exhale_tone.mp3',
      },
      {
        stepIndex: 3,
        displayName: 'hold',
        duration: 3,
        scaleFactor: 1,
        increment: 0,
        initialGraphHeight: 0,
        targetGraphHeight: 0,
      },
    ],
  },
  {
    guideId: '4d1fcf81-69e8-4b8c-8d21-6edf6486ff4f',
    category: 'Calm',
    name: 'Relax Exercise',
    preExerciseQuestionnaireId: '26be1994-f2c6-4034-a759-5e9b45f5238a',
    postExerciseQuestionnaireId: '26be1994-f2c6-4034-a759-5e9b45f5238a',
    maxTime: 120,
    backgroundAudioFileName: 'burgs.mp3',
    exerciseLoop: [
      {
        stepIndex: 0,
        displayName: 'inhale',
        duration: 4,
        scaleFactor: 1,
        increment: 0,
        initialGraphHeight: 0,
        targetGraphHeight: 5,
        audioFileName: 'inhale_tone.mp3',
      },
      {
        stepIndex: 1,
        displayName: 'hold',
        duration: 7,
        scaleFactor: 1,
        increment: 0,
        initialGraphHeight: 5,
        targetGraphHeight: 5,
      },
      {
        stepIndex: 2,
        displayName: 'exhale',
        duration: 8,
        scaleFactor: 1,
        increment: 0,
        initialGraphHeight: 5,
        targetGraphHeight: 0,
        audioFileName: 'exhale_tone.mp3',
      },
    ],
  },
  {
    guideId: '06d34f0a-139f-4ff8-838f-de8bcccd5579',
    category: 'Creativity',
    name: 'Increasing Exhale',
    preExerciseQuestionnaireId: '26be1994-f2c6-4034-a759-5e9b45f5238a',
    postExerciseQuestionnaireId: '26be1994-f2c6-4034-a759-5e9b45f5238a',
    maxTime: 90,
    exerciseLoop: [
      {
        stepIndex: 0,
        displayName: 'inhale',
        duration: 5,
        scaleFactor: 1,
        increment: 0,
        initialGraphHeight: 0,
        targetGraphHeight: 5,
        audioFileName: 'inhale_tone.mp3',
      },
      {
        stepIndex: 1,
        displayName: 'exhale',
        duration: 5,
        scaleFactor: 1,
        increment: 1,
        initialGraphHeight: 5,
        targetGraphHeight: 0,
        audioFileName: 'exhale_tone.mp3',
      },
    ],
  },
];
