import React, { useEffect, useState } from 'react';
import './BreathGuideIndicator.scss';
import PropTypes from 'prop-types';

const BreathGuideIndicator = (props) => {
  const { info, duration } = props;
  const [transitionDuration, setTransDuration] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(0);
    setTransDuration(0);

    setTimeout(() => {
      setTransDuration(duration);
      setWidth('calc(100% + 2px)');
    }, 20);
  }, [info, duration]);

  return (
    <div className="breath-guide-indicator">
      {duration && <div className="breath-guide-indicator__black-background" />}
      {duration && <div className="breath-guide-indicator__green-background" style={{ transitionDuration: `${transitionDuration}s`, width: `${width}` }} />}
      {info && <p className="breath-guide-indicator__text">{info}</p>}
    </div>
  );
};

BreathGuideIndicator.propTypes = {
  info: PropTypes.string,
  duration: PropTypes.number,
};

BreathGuideIndicator.defaultProps = {
  info: null,
  duration: null,
};

export default BreathGuideIndicator;
