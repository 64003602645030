import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';

import Tooltip from '../components/Tooltip';

export default function BpmGraph({ data }) {
  return (
    <ResponsiveLine
      data={data}
      margin={{
        top: 10, right: 0, bottom: 30, left: 0,
      }}
      xScale={{ type: 'linear' }}
      yScale={{
        type: 'linear', stacked: false, min: 'auto', max: 'auto',
      }}
      curve="basis"
      axisBottom={null}
      axisLeft={null}
      enableGridX={false}
      enableGridY={false}
      theme={{
        crosshair: {
          line: {
            strokeDasharray: '0',
          },
        },
      }}
      colors="#00E4C3"
      enablePoints={false}
      useMesh
      crosshairType="bottom"
      tooltip={({ point }) => (
        <Tooltip time={Math.round(point.data.x)} />
      )}
    />
  );
}

BpmGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};
