/* eslint-disable import/prefer-default-export */
export function getAvgPosXOfLast(number, array) {
  const samplesNumber = array.length < number ? array.length : number;
  const samples = array.slice(-samplesNumber)
    .map((el) => el.x)
    .filter((el) => el !== 0);

  const result = samples.reduce((a, b) => a + b, 0) / samples.length;
  return result || null;
}
