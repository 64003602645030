import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Auth } from 'aws-amplify';
import classNames from 'classnames';

import { userSettingsFailure, userErrorClear } from 'app/store/actions/userActions';
import { cvSelectors } from 'app/store/selectors/cv';
import { userSelectors } from 'app/store/selectors/userSelectors';

import './Settings.scss';


class Settings extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      user: '',
      oldPassword: '',
      password: '',
      firstName: '',
      lastName: '',
      success: '',
    };

    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeUserName = this.handleChangeUserName.bind(this);
    this.handleChangeUserSurname = this.handleChangeUserSurname.bind(this);
  }

  async componentDidMount() {
    const { history } = this.props;

    try {
      const user = await Auth.currentAuthenticatedUser();
      this.setState({
        user,
        firstName: user.attributes.given_name,
        lastName: user.attributes.family_name,
      });
    } catch (err) {
      history.push('/');
    }
  }

  handleInputChange(event) {
    const { userErrorClearAction, error } = this.props;
    const { success } = this.state;

    this.setState({
      [event.target.id]: event.target.value,
    });

    if (error) {
      userErrorClearAction();
    }

    if (success) {
      this.setState({ success: '' });
    }
  }

  async handleChangePassword(event) {
    event.preventDefault();
    const { user, password, oldPassword } = this.state;
    const { userSettingsFailureAction, userErrorClearAction } = this.props;
    userErrorClearAction();
    this.setState({ success: '' });

    try {
      await Auth.changePassword(user, oldPassword, password);
      this.setState({ success: 'successfully changed password' });
    } catch (err) {
      userSettingsFailureAction(err);
    }
  }

  async handleChangeUserName(event) {
    event.preventDefault();
    const { user, firstName } = this.state;
    const { userSettingsFailureAction, userErrorClearAction } = this.props;
    userErrorClearAction();
    this.setState({ success: '' });

    try {
      await Auth.updateUserAttributes(user, { given_name: firstName });
      this.setState({ success: 'successfully changed first name' });
      Auth.currentAuthenticatedUser({ bypassCache: true });
    } catch (err) {
      userSettingsFailureAction(err);
    }
  }

  async handleChangeUserSurname(event) {
    event.preventDefault();
    const { user, lastName } = this.state;
    const { userSettingsFailureAction, userErrorClearAction } = this.props;
    userErrorClearAction();
    this.setState({ success: '' });

    try {
      await Auth.updateUserAttributes(user, { family_name: lastName });
      this.setState({ success: 'successfully changed last name' });
      Auth.currentAuthenticatedUser({ bypassCache: true });
    } catch (err) {
      userSettingsFailureAction(err);
    }
  }

  render() {
    const { firstName, lastName, success } = this.state;
    const { error } = this.props;

    return (
      <div className="container">
        <div className="settings">
          <h2 className="settings__title">Settings</h2>
          <div className={classNames('settings__container', {
            'settings__error-container': error,
          })}
          >
            {error}
          </div>
          <div className={classNames('settings__container', {
            'settings__success-container': success,
          })}
          >
            {success}
          </div>
          <div className="settings__password">
            <h3 className="settings__headline">Change Password</h3>
            <form onSubmit={this.handleChangePassword}>
              <div className="settings__inputs">
                <input
                  className="settings__input"
                  id="oldPassword"
                  key="oldPassword"
                  name="oldPassword"
                  onChange={this.handleInputChange}
                  type="password"
                  placeholder="old password"
                />
                <input
                  className="settings__input"
                  id="password"
                  key="password"
                  name="password"
                  onChange={this.handleInputChange}
                  type="password"
                  placeholder="new password"
                />
              </div>
              <button
                className="button"
                type="submit"
              >
                  Change password
              </button>
            </form>
          </div>
          <div className="settings__first-name">
            <h3 className="settings__headline">First Name</h3>
            <form onSubmit={this.handleChangeUserName}>
              <input
                className="settings__input"
                id="firstName"
                key="firstName"
                name="firstName"
                onChange={this.handleInputChange}
                value={firstName}
                type="text"
                placeholder="First name"
              />
              <button
                className="button"
                type="submit"
              >
                Change first name
              </button>
            </form>
          </div>
          <div className="settings__last-name">
            <h3 className="settings__headline">Last Name</h3>
            <form onSubmit={this.handleChangeUserSurname}>
              <input
                className="settings__input"
                id="lastName"
                key="lastName"
                name="lastName"
                value={lastName}
                onChange={this.handleInputChange}
                type="text"
                placeholder="Last name"
              />
              <button
                className="button"
                type="submit"
              >
                Change last name
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Settings.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  userSettingsFailureAction: PropTypes.func.isRequired,
  userErrorClearAction: PropTypes.func.isRequired,
  error: PropTypes.string,
};

Settings.defaultProps = {
  error: '',
};

const mapStateToProps = (state) => ({
  error: userSelectors.getUserErrors(state),
  respInfo: cvSelectors.getRespInfo(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  userSettingsFailureAction: userSettingsFailure,
  userErrorClearAction: userErrorClear,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Settings));
