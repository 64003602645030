import React from 'react';
import { ConfirmSignUp } from 'aws-amplify-react';

import './CustomConfirmSignUp.scss';

class CustomConfirmSignUp extends ConfirmSignUp {
  constructor(props) {
    super(props);
    // eslint-disable-next-line no-underscore-dangle
    this._validAuthStates = ['confirmSignUp'];
  }

  // eslint-disable-next-line no-unused-vars
  showComponent(themeIn) {
    const username = this.usernameFromAuthData();

    return (
      <div className="auth">
        <div className="auth__content">
          <div className="confirm-sign-up">
            <h1 className="confirm-sign-up__headline">
              Confirm Sign Up
            </h1>
            <p className="confirm-sign-up__text">Please enter your confirmation code</p>
            <form>
              <div className="confirm-sign-up__inputs">
                {username ? (
                  <input
                    value={username}
                    disabled
                    className="confirm-sign-up__input"
                    type="text"
                    key="username"
                    name="username"
                    placeholder="Email"
                    onChange={this.handleInputChange}
                  />
                ) : (
                  <input
                    className="confirm-sign-up__input"
                    type="text"
                    key="username"
                    name="username"
                    placeholder="Email"
                    onChange={this.handleInputChange}
                  />
                )}
                <input
                  className="confirm-sign-up__input"
                  type="text"
                  key="code"
                  name="code"
                  placeholder="Code"
                  onChange={this.handleInputChange}
                />
              </div>
              <button type="button" className="button" onClick={this.confirm}>
                Submit
              </button>
            </form>
            <div className="confirm-sign-up__links-container">
              <button
                type="button"
                className="confirm-sign-up__text"
                onClick={this.resend}
              >
                Resend Code
              </button>
              <button
                type="button"
                className="confirm-sign-up__text"
                onClick={() => this.changeState('signIn')}
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
        <div className="auth__image" />
      </div>
    );
  }
}

export default CustomConfirmSignUp;
