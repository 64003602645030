import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import settingIcon from 'static/icons/icon_header_settings.svg';
import homeIcon from 'static/icons/icon_header_home.svg';
import resultsIcon from 'static/icons/icon_header_results.svg';
import signOutIcon from 'static/icons/icon_header_sign_out.svg';
import userAvatar from 'static/icons/user_avatar_32x32.svg';

import './HeaderNavigation.scss';

function HeaderNavigation({
  onAvatarClick,
  openDropdown,
  onLogOut,
  attributes,
}) {
  return (
    <>
      <div className="header-navigation__left-info">
        <span className="header-navigation__heading">Floe (Beta)</span>
      </div>
      <div className="container header-navigation__navigation">
        <ul className="header-navigation__links">
          <li>
            <NavLink to="/" exact className="header-navigation__link" activeClassName="header-navigation__link--active">
              <img src={homeIcon} alt="" />
              <span className="header-navigation__text">Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/result" className="header-navigation__link" activeClassName="header-navigation__link--active">
              <img src={resultsIcon} alt="" />
              <span className="header-navigation__text">Result</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings" className="header-navigation__link" activeClassName="header-navigation__link--active">
              <img src={settingIcon} alt="" />
              <span className="header-navigation__text">Settings</span>
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="header-navigation__right-info">
        <button type="button" onClick={onAvatarClick}>
          {
            attributes && attributes.picture
              ? <img src={attributes && attributes.picture ? attributes.picture : userAvatar} alt="avatar" className="header-navigation__user-picture" />
              : <div className="header-navigation__name-picture">{attributes && attributes.given_name ? attributes.given_name.charAt(0) : attributes.email && attributes.email.charAt(0)}</div>
          }
        </button>
        <div className={classNames('header-navigation__dropdown-container', {
          'header-navigation__dropdown-container--open': openDropdown,
        })}
        >
          <ul>
            <li className="header-navigation__dropdown-option" onClick={onLogOut}>
              <img src={signOutIcon} alt="" />
              <span className="header-navigation__text">Log out</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

HeaderNavigation.defaultProps = {
  attributes: {},
};

HeaderNavigation.propTypes = {
  onAvatarClick: PropTypes.func.isRequired,
  onLogOut: PropTypes.func.isRequired,
  openDropdown: PropTypes.bool.isRequired,
  attributes: PropTypes.shape({
    email: PropTypes.string,
    family_name: PropTypes.string,
    given_name: PropTypes.string,
    identities: PropTypes.string,
    picture: PropTypes.string,
    sub: PropTypes.string,
  }),
};

export default HeaderNavigation;
