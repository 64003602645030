// Loading this way, just to be sure it is always loaded after React,
// so 'onRuntimeInitialized' call back.

/**
 * Function appends floe_sdk script to documents body.
 * Please load sdk only once per session.
 * @param {*} callback callback function called when floe_sdk is ready
 */
export default function loadFloeSdk(callback) {
  const cvScript = document.createElement('script');
  document.body.appendChild(cvScript);
  cvScript.src = 'floe_sdk.js';
  cvScript.async = false;
  cvScript.onload = () => {
    window.cv.onRuntimeInitialized = () => {
      callback();
    };
  };
}
