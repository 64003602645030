import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import GuideGraph from 'app/common/graphs/GuideGraph';
import BpmGraph from 'app/common/graphs/BpmGraph';
import BreathsGraph from 'app/common/graphs/BreathsGraph';
import StreakGraph from 'app/common/graphs/StreakGraph';

import './Result.scss';

class Result extends PureComponent {
  render() {
    const {
      longestStreakPeriod,
      graphHeighs,
      bpm,
      groupedBreaths,
      averageAccuracyProcent,
      streak,
      breathsPerMinute,
      breaths,
      activeSessionDate,
      startStreak,
      isActualSession,
      isFetchingSession,
      isFirstSession,
      typeOfExercise,
    } = this.props;

    return (
      <>
        <div className="results__header">
          {
            !isFetchingSession
              ? !!activeSessionDate && <h2 className="results__title">{(isActualSession && isFirstSession) ? 'This Session' : moment(activeSessionDate).format('DD MMM YYYY')}</h2>
              : <h2 className="results__title">Loading...</h2>
          }
          {
            !isFetchingSession && !activeSessionDate && <h2 className="results__title">No session Data</h2>
          }
          {
            typeOfExercise && <Link to="/calibration"><button type="button" className="button">New Session</button></Link>
          }
        </div>
        <div className="results__accuracy">
          <p className="results__text">Total accuracy</p>
          <span className="results__value">{averageAccuracyProcent}%</span>
        </div>
        <div className="results__guide-graph">
          <GuideGraph data={graphHeighs} />
        </div>
        <div className="results__rest-graphs">
          <div className="results__left-graphs">
            <div className="results__graphs-container">
              <p className="results__text">Breaths per minute</p>
              <span className="results__value">{breathsPerMinute}</span>
              <div className="results__bpm-graph">
                <BpmGraph data={bpm} />
              </div>
            </div>
            <div className="results__graphs-container">
              <p className="results__text">Total breaths</p>
              <span className="results__value">{breaths.length}</span>
              <div className="results__breaths-graph">
                <BreathsGraph data={groupedBreaths} />
              </div>
            </div>
          </div>
          <div className="results__right-graphs">
            <p className="results__text">Longest streak</p>
            <span className="results__value">{longestStreakPeriod}s</span>
            <div className="results__streak-container">
              <div className="results__streak-graph">
                <StreakGraph data={streak} startStreak={startStreak} />
              </div>
              <p className="results__text">A streak measures how long you maintained an accuracy level above 90%.</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Result.propTypes = {
  breaths: PropTypes.arrayOf(PropTypes.array).isRequired,
  longestStreakPeriod: PropTypes.number.isRequired,
  graphHeighs: PropTypes.arrayOf(PropTypes.object).isRequired,
  bpm: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupedBreaths: PropTypes.arrayOf(PropTypes.object).isRequired,
  averageAccuracyProcent: PropTypes.number.isRequired,
  streak: PropTypes.arrayOf(PropTypes.object).isRequired,
  breathsPerMinute: PropTypes.number.isRequired,
  activeSessionDate: PropTypes.number.isRequired,
  startStreak: PropTypes.number.isRequired,
  isActualSession: PropTypes.bool.isRequired,
  isFetchingSession: PropTypes.bool.isRequired,
  isFirstSession: PropTypes.bool.isRequired,
  typeOfExercise: PropTypes.string.isRequired,
};

export default Result;
