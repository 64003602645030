import { questionsMock } from '../../mockup/questions';
import { guidesMock } from '../../mockup/guides';

const PREFIX = '[EXERCISE]';

export const SET_TYPE = `${PREFIX} SET_TYPE`;
export const GET_QUESTIONS_REQUEST = `${PREFIX} GET_QUESTIONS_REQUEST`;
export const GET_QUESTIONS_SUCCESS = `${PREFIX} GET_QUESTIONS_SUCCESS`;
export const GET_QUESTIONS_FAILURE = `${PREFIX} GET_QUESTIONS_FAILURE`;
export const SET_PRE_SESSION_ANSWERS = `${PREFIX} SET_PRE_SESSION_ANSWERS`;
export const SET_POST_SESSION_ANSWERS = `${PREFIX} SET_POST_SESSION_ANSWERS`;
export const GET_GUIDES_REQUEST = `${PREFIX} GET_GUIDES_REQUEST`;
export const GET_GUIDES_SUCCESS = `${PREFIX} GET_GUIDES_SUCCESS`;
export const GET_GUIDES_FAILURE = `${PREFIX} GET_GUIDES_FAILURE`;
export const SET_ACTUAL_SESSION = `${PREFIX} SET_ACTUAL_SESSION`;

export const setExerciseType = (payload) => ({
  type: SET_TYPE,
  payload,
});

export const actualSession = () => ({
  type: SET_ACTUAL_SESSION,
});

const getQuestionsRequest = () => ({
  type: GET_QUESTIONS_REQUEST,
});

const getQuestionsSuccess = (questions) => ({
  type: GET_QUESTIONS_SUCCESS,
  questions,
});

const getQuestionsFailure = () => ({
  type: GET_QUESTIONS_FAILURE,
});

export const getQuestions = () => (dispatch) => {
  dispatch(getQuestionsRequest());

  return Promise.resolve(questionsMock)
    .then((questions) => dispatch(getQuestionsSuccess(questions)))
    .catch((error) => {
      dispatch(getQuestionsFailure());

      throw error;
    });
};

export const setPreSessionAnswers = (answers) => ({
  type: SET_PRE_SESSION_ANSWERS,
  answers,
});

export const setPostSessionAnswers = (answers) => ({
  type: SET_POST_SESSION_ANSWERS,
  answers,
});
const getGuidesRequest = () => ({
  type: GET_GUIDES_REQUEST,
});

const getGuidesSuccess = (guides) => ({
  type: GET_GUIDES_SUCCESS,
  guides,
});

const getGuidesFailure = () => ({
  type: GET_GUIDES_FAILURE,
});

export const getGuides = () => (dispatch) => {
  dispatch(getGuidesRequest());

  return Promise.resolve(guidesMock)
    .then((guides) => dispatch(getGuidesSuccess(guides)))
    .catch((error) => {
      dispatch(getGuidesFailure());

      throw error;
    });
};
