import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import './Session.scss';

class Session extends PureComponent {
  render() {
    const {
      exerciseTime,
      averageAccuracy,
      sessions,
      activeSessionId,
      onChangeSession,
      isActualSession,
    } = this.props;

    return (
      <div className="session">
        <div>
          <div className="session__description-container">
            <p className="session__text session__text--lighter">session time</p>
            <span className="session__text">{exerciseTime}s</span>
          </div>
          <div className="session__description-container">
            <p className="session__text session__text--lighter">session accuracy</p>
            <span className="session__text">{averageAccuracy}%</span>
          </div>
        </div>
        <div className="session__list">
          {
            sessions && sessions.map((session, index) => (
              <button
                key={session.SessionId}
                className={
                  classNames('session__text session__text--lighter session__item-list', {
                    session__current: session.SessionId === activeSessionId,
                  })
                }
                type="button"
                onClick={onChangeSession}
                data-id={session.SessionId}
              >
                { (index === 0 && isActualSession) ? 'This session' : moment(session.StartTime).format('DD MMM YYYY')}
              </button>
            ))
          }
        </div>
      </div>
    );
  }
}

Session.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
  exerciseTime: PropTypes.number.isRequired,
  averageAccuracy: PropTypes.number.isRequired,
  activeSessionId: PropTypes.string.isRequired,
  onChangeSession: PropTypes.func.isRequired,
  isActualSession: PropTypes.bool.isRequired,
};

export default Session;
