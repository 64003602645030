import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './BreathTimer.scss';


const BreathTimer = (props) => {
  const { time, triggerAnimation } = props;
  const [timerClass, setTimerClass] = useState('breath-timer');


  useEffect(() => {
    setTimerClass('breath-timer');
    setTimeout(() => setTimerClass('breath-timer breath-timer--hidden'), 10);
  }, [triggerAnimation]);

  return (
    <p className={timerClass}>{time}</p>
  );
};

BreathTimer.propTypes = {
  time: PropTypes.string.isRequired,
  triggerAnimation: PropTypes.shape({}),
};

BreathTimer.defaultProps = {
  triggerAnimation: null,
};

export default BreathTimer;
