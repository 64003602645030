import { createGuideArray } from 'app/common/utils/createGuideArray';

/* eslint-disable */
var guide = {
    "name": "Box Exercise",
    "category": "Focus",  
    "maxTime": 180,
    "exerciseLoop": [
      {
        "stepIndex": 0,
        "displayName": "inhale",
        "duration": 3,
        "scaleFactor": 1,
        "increment": 0,
        "initialGraphHeight": 0,
        "targetGraphHeight": 5,
      },
      {
        "stepIndex": 1,
        "displayName": "hold",
        "duration": 3,
        "scaleFactor": 1,
        "increment": 0,
        "initialGraphHeight": 5,
        "targetGraphHeight": 5,
      },
      {
        "stepIndex": 2,
        "displayName": "exhale",
        "duration": 3,
        "scaleFactor": 1,
        "increment": 0,
        "initialGraphHeight": 5,
        "targetGraphHeight": 0,
      },
      {
        "stepIndex": 3,
        "displayName": "hold",
        "duration": 3,
        "scaleFactor": 1,
        "increment": 0,
        "initialGraphHeight": 0,
        "targetGraphHeight": 0,
      }
    ]
    };



function calculateAccuracy(guideBreaths, breaths) {
  
    var guideAccuracy = [];

    var lastBreathEnd = 0;
    if(breaths.length > 0)
        lastBreathEnd = breaths[breaths.length-1][1];
   
    for (var i = 0; i < guideBreaths.length; i++) {

        let intersectBreaths = [];
        let guideStart = guideBreaths[i][0];
        let guideEnd = guideBreaths[i][1];

        if(guideStart > lastBreathEnd)
            break;

        let nFullyContained = 0;
        let fullyContainedIndex = 0;

        for (var j = 0; j < breaths.length; j++) {
            var breathStart = breaths[j][0];
            var breathEnd = breaths[j][1];

            if(breathStart > guideEnd)
                break;

            if(breathStart < guideEnd && breathEnd > guideStart){
                intersectBreaths.push(breaths[j]);

                if( breathStart >= guideStart && breathEnd <= guideEnd){
                    nFullyContained++;
                    fullyContainedIndex = intersectBreaths.length - 1;
                }
            }
        }

        if (intersectBreaths.length == 0 || nFullyContained > 1){

            guideAccuracy.push(0);

        } else if (nFullyContained == 1) {

            var accuracy = getBreathSimilarity(guideBreaths[i], intersectBreaths[fullyContainedIndex]);
            guideAccuracy.push(accuracy);

        } else {

             var maxAccuracy = 0;

             for (var j = 0; j< intersectBreaths.length; j++) {
              
                   var accuracy = getBreathSimilarity(guideBreaths[i], intersectBreaths[j]);
                   maxAccuracy = Math.max(maxAccuracy , accuracy);
                 
             }
             guideAccuracy.push(maxAccuracy);           
        }     
    }  

    return guideAccuracy; 
}

function getBreathSimilarity(guide, breath) {

    var startSimilarity = getSimilarity(breath[0], guide[0], (guide[1] - guide[0])/4);
    var endSimilarity   = getSimilarity(breath[1], guide[1], (guide[1] - guide[0])/4);

    var similarity = (startSimilarity + endSimilarity)/2.0;
    return Math.round(similarity*100)/100;

}

function getSimilarity(x, M, S) {
    return Math.exp( -(  (Math.pow((x-M),6)*1.0)  /  (2.0*Math.pow(S, 6) )  )  );
}

function getLongestStreak(breaths, accuracies){

  var maxStreak = 0;
  var maxStreakBreath = [];
  var streakAccuracy = 0.9;
  var inStreak = false;
  var streakStart = 0;

  for (var i = 0; i < breaths.length; i++) {
      if(accuracies[i] >= streakAccuracy){
          if(!inStreak)
              streakStart = breaths[i][0];
              
          inStreak = true;
      } else {
         if(inStreak){
             var streak = breaths[i][0] - streakStart;
             if(streak > maxStreak){
                maxStreak = streak;
                maxStreakBreath = [streakStart, breaths[i][0]];
                
             }
         }

         inStreak = false;
      }
  }

  if(inStreak){
       var streak = breaths[breaths.length-1][0] - streakStart;
       if(streak > maxStreak){
           maxStreak = streak;
           maxStreakBreath = [streakStart, breaths[breaths.length-1][0]];  
       }
  }

  return maxStreakBreath;
}


function getAverageAccuracy(array){
    var sum = 0;
    for( var i = 0; i < array.length; i++ ){
        sum += array[i]; 
    }
    return  sum/array.length;
}

function calculateDurations(guide){

   var durations = [];
   var t = 0;
   var i = 0;
   while(t < guide.maxTime) {

       for(var j = 0; j < guide.exerciseLoop.length; j++){

           var duration = (Math.pow(guide.exerciseLoop[j].scaleFactor, i) * guide.exerciseLoop[j].duration) + guide.exerciseLoop[j].increment * i;
           t += duration;
           durations.push(duration);
       }
       i++;
   }

   return durations;
}

function calculateBreaths(guide){

   var guideBreaths = [];
   var t = 0;
   var i = 0;
   while(t < guide.maxTime) {

       var inhale = 0;
       var exhale = 0;

       for(var j = 0; j < guide.exerciseLoop.length; j++){

           // get the start of the inhale
           if(guide.exerciseLoop[j].initialGraphHeight == 0 && guide.exerciseLoop[j].targetGraphHeight > 0){
               inhale = t;
           }  

           var duration = (Math.pow(guide.exerciseLoop[j].scaleFactor, i) * guide.exerciseLoop[j].duration) + guide.exerciseLoop[j].increment * i;
           t += duration;

           // get the end of the exhale
           if (guide.exerciseLoop[j].initialGraphHeight > 0 && guide.exerciseLoop[j].targetGraphHeight == 0){
               exhale = t;
           } 

       }
       guideBreaths.push([inhale, exhale]);
       i++;
   }

   return guideBreaths;
}

function getBreathHistogram(breaths, blockSize) {
  var nBlocks = 0;

  if(breaths.length > 0)
      nBlocks = Math.ceil(breaths[breaths.length-1][1]/blockSize);

  var hist = Array(nBlocks).fill(0);

  for (var i = 0; i < breaths.length; i++) {
    var breathEnd = breaths[i][1];
    var index = Math.floor(breathEnd/blockSize);
    hist[index]++;
  }

  return hist;
}

//Merix functions
/* eslint-enable */

export function longestStreakHandler(breaths, guideObject) {
  const guideBreaths = calculateBreaths(guideObject);
  const breathAccuracies = calculateAccuracy(guideBreaths, breaths);
  const longestStreak = getLongestStreak(breaths, breathAccuracies);
  const longestStreakRounded = longestStreak.length
    ? longestStreak.map((time) => Math.round(time))
    : [0, 0];

  return longestStreakRounded;
}

export function averageAccuracyHandler(breaths, guideObject) {
  const guideBreaths = calculateBreaths(guideObject);
  const breathAccuracies = calculateAccuracy(guideBreaths, breaths);
  const averageAccuracy = getAverageAccuracy(breathAccuracies);

  return averageAccuracy;
}

export function graphHeightHandler(respInfo, guideObject) {
  const data = respInfo.map((resp) => ({ x: resp.time, y: resp.graphHeight }));
  const measured = {
    id: 'measured',
    data,
  };
  let excerciseTime = 0;

  if (respInfo && respInfo.length) {
    excerciseTime = Math.floor(respInfo[respInfo.length - 1].time);
  }

  const guideArray = guideObject ? createGuideArray(guideObject, excerciseTime) : [];
  const guideStart = { x: 0, y: 0 };
  const guideData = [guideStart, ...guideArray.map((el) => ({ x: el.time, y: el.graphHeight }))];

  const guideGraph = {
    id: 'guide',
    data: guideData,
  };

  const graphHeights = [
    measured,
    guideGraph,
  ];

  return graphHeights;
}

function bpmDataHandler(respInfo) {
  const bpmData = respInfo.map((resp) => ({ x: resp.time, y: resp.respRate }));

  return bpmData;
}

export function bpmHandler(respInfo) {
  const data = respInfo.length ? bpmDataHandler(respInfo) : [{ x: 0, y: 0 }];

  const bpm = [{
    id: 'bpm',
    data,
  }];

  return bpm;
}

export function groupedBreathsHandler(breaths) {
  const blocks = getBreathHistogram(breaths, 30);
  const breathsGraphData = [];
  let time = 0;
  for (let i = 0; i < blocks.length; i += 1) {
    const startMinutes = Math.floor(time / 60);
    const endMinutes = Math.floor((time + 30) / 60);
    const startSeconds = time - startMinutes * 60;
    const endSeconds = (time + 30) - endMinutes * 60;
    const startTime = `${startMinutes}:${startSeconds || '00'}`;
    const endTime = `${endMinutes}:${endSeconds || '00'}`;
    breathsGraphData.push({ breath: blocks[i], time: `${startTime} - ${endTime}` });
    time += 30;
  }

  return breathsGraphData;
}
