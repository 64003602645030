import React, { PureComponent } from 'react';
import { API, Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import { fetchSessions, fetchSessionsFailure } from 'app/store/actions/sessionsActions';
import { setExerciseType, getGuides } from 'app/store/actions/exerciseActions';
import { exerciseSelectors } from 'app/store/selectors/exerciseSelectors';
import { sessionsSelectors } from 'app/store/selectors/sessionsSelectors';

import {
  averageAccuracyHandler,
  graphHeightHandler,
} from 'app/common/utils/calculation';

import Dashboard from './Dashboard';


class DashboardContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      averageAccuracy: 0,
      graphHeighs: [],
      isEmptyState: false,
      isFetchingSession: true,
      firstName: '',
    };

    this.fetchSessions = this.fetchSessions.bind(this);
    this.handleStartSession = this.handleStartSession.bind(this);
  }

  async componentDidMount() {
    const { getGuidesAction } = this.props;
    await getGuidesAction();
    const user = await Auth.currentAuthenticatedUser();

    await this.fetchSessions(user.username);
    const { sessions, guides } = this.props;
    const guideObject = sessions[0] && guides.find(
      (guide) => guide.guideId === sessions[0].SessionData.GuideId,
    );

    if (sessions[0]) {
      this.setState({
        averageAccuracy: averageAccuracyHandler(sessions[0].SessionData.breaths, guideObject),
        graphHeighs: graphHeightHandler(sessions[0].SessionData.respInfo, guideObject),
      });
    } else {
      this.setState({
        isEmptyState: true,
      });
    }

    this.setState({
      firstName: user.attributes.given_name,
      isFetchingSession: false,
    });
  }

  async fetchSessions(username) {
    const { fetchSessionsAction, fetchSessionsFailureAction } = this.props;

    try {
      const sessions = await API.get('SessionAPI', '/sessions', {
        queryStringParameters: {
          user_id: username,
        },
      });
      const sortedSessions = sessions.data.sort((a, b) => b.StartTime - a.StartTime);
      fetchSessionsAction(sortedSessions);
    } catch (error) {
      fetchSessionsFailureAction(error);
    }
  }

  async handleStartSession(event) {
    const { setExerciseTypeAction, history } = this.props;

    await setExerciseTypeAction(event.target.dataset.type);
    history.push('/instruction');
  }

  render() {
    const {
      averageAccuracy,
      graphHeighs,
      isFetchingSession,
      isEmptyState,
      firstName,
    } = this.state;

    const averageAccuracyProcent = Math.round(averageAccuracy * 100);

    return (
      <Dashboard
        averageAccuracyProcent={averageAccuracyProcent}
        graphHeighs={graphHeighs}
        onStartSession={this.handleStartSession}
        isFetchingSession={isFetchingSession}
        isEmptyState={isEmptyState}
        firstName={firstName}
      />
    );
  }
}

DashboardContainer.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchSessionsAction: PropTypes.func.isRequired,
  fetchSessionsFailureAction: PropTypes.func.isRequired,
  setExerciseTypeAction: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  guides: PropTypes.arrayOf(PropTypes.object).isRequired,
  getGuidesAction: PropTypes.func.isRequired,

};

const mapStateToProps = (state) => ({
  sessions: sessionsSelectors.getSessionsData(state),
  guides: exerciseSelectors.getGuides(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchSessionsAction: fetchSessions,
  fetchSessionsFailureAction: fetchSessionsFailure,
  setExerciseTypeAction: setExerciseType,
  getGuidesAction: getGuides,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(DashboardContainer));
