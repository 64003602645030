import React from 'react';
import { SignUp } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';

import './CustomSignUp.scss';

export default class CustomSignUp extends SignUp {
  constructor(props) {
    super(props);
    // eslint-disable-next-line no-underscore-dangle
    this._validAuthStates = ['signUp'];
    this.handleCreateAccount = this.handleCreateAccount.bind(this);
  }

  async handleCreateAccount(event) {
    event.preventDefault();
    const {
      username,
      password,
      firstName,
      lastName,
    } = this.inputs;
    const email = username;
    const attributes = { family_name: lastName, given_name: firstName };

    // After change settings in cognito user pool required, this conditions will be redundant
    if (!firstName) {
      return this.error('First name field cannot be empty');
    }

    if (!lastName) {
      return this.error('Last name field cannot be empty');
    }

    if (this.inputs.password && this.inputs.password.length < 8) {
      return this.error('Password too short');
    }

    try {
      await Auth.signUp({
        username,
        password,
        email,
        attributes,
      });

      return this.changeState('confirmSignUp', username);
    } catch (err) {
      return this.error(err);
    }
  }

  // eslint-disable-next-line no-unused-vars
  showComponent(theme) {
    return (
      <div className="auth">
        <div className="auth__content">
          <div className="sign-up">
            <h2 className="sign-up__headline">Create a new account</h2>
            <button type="button" className="sign-in__text" onClick={() => super.changeState('signIn')}>
              or log in
            </button>
            <form>
              <div className="sign-up__inputs">
                <input
                  className="sign-up__input"
                  id="username"
                  key="username"
                  name="username"
                  onChange={this.handleInputChange}
                  type="email"
                  placeholder="Email address"
                />
                <input
                  className="sign-up__input"
                  id="password"
                  key="password"
                  name="password"
                  onChange={this.handleInputChange}
                  type="password"
                  placeholder="Password"
                />
                <input
                  className="sign-up__input"
                  id="firstName"
                  key="firstName"
                  name="firstName"
                  onChange={this.handleInputChange}
                  type="text"
                  placeholder="First name"
                />
                <input
                  className="sign-up__input"
                  id="lastName"
                  key="lastName"
                  name="lastName"
                  onChange={this.handleInputChange}
                  type="text"
                  placeholder="Last name"
                />
              </div>
              <button
                className="button"
                type="submit"
                onClick={this.handleCreateAccount}
              >
                Create Account
              </button>
            </form>
            <div className="sign-up__line" />
          </div>
        </div>
        <div className="auth__image" />
      </div>
    );
  }
}
