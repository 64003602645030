/* eslint-disable import/prefer-default-export */
export const calibrationEdgeValues = {
  faceXMax: 64, // max differ from center of the screen
  faceYMax: 120, // highest face posiotion in Y axis
  faceSizeMin: 24, // min face height
  faceSizeMax: 100, // max face height
};

export const videoResolution = {
  width: 320,
  height: 240,
};

export const samplingData = 8;
