import React from 'react';
import { SignIn, withOAuth } from 'aws-amplify-react';

import facebookIcon from 'static/icons/icon_login_facebook.svg';
import googleIcon from 'static/icons/icon_login_google.svg';
import './CustomSignIn.scss';

class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    // eslint-disable-next-line no-underscore-dangle
    this._validAuthStates = ['signIn', 'signedUp'];
    this.handleSigIn = this.handleSigIn.bind(this);
  }

  handleSigIn() {
    if (!this.inputs.password) {
      return this.error('Password field cannot be empty');
    }

    if (this.inputs.password && this.inputs.password.length < 8) {
      return this.error('Password too short');
    }

    return super.signIn();
  }

  // eslint-disable-next-line no-unused-vars
  showComponent(theme) {
    return (
      <div className="auth">
        <div className="auth__content">
          <div className="sign-in">
            <h2 className="sign-in__headline">Log in</h2>
            {/*<button type="button" className="sign-in__text" onClick={() => super.changeState('signUp')}>
              or create an account
            </button>*/}
            <form>
              <div className="sign-in__inputs">
                <input
                  className="sign-in__input"
                  id="username"
                  key="username"
                  name="username"
                  onChange={this.handleInputChange}
                  type="text"
                  placeholder="Email address"
                />
                <input
                  className="sign-in__input"
                  id="password"
                  key="password"
                  name="password"
                  onChange={this.handleInputChange}
                  type="password"
                  placeholder="Password"
                />
              </div>
              <button
                className="button"
                type="button"
                onClick={this.handleSigIn}
              >
                Login
              </button>
              <p className="sign-in__forgot-password">
                <button
                  type="button"
                  className="sign-in__text"
                  onClick={() => super.changeState('forgotPassword')}
                >
                  Forgot your password?
                </button>
              </p>
            </form>
            {/*<div className="sign-in__line" />
            <h2 className="sign-in__headline">Or continue with</h2>
            <div>
              <button type="button" className="sign-in__social-icon" onClick={this.props.OAuthSignIn}>
                <img src={facebookIcon} alt="facebook" />
              </button>
              <button type="button" className="sign-in__social-icon" onClick={this.props.OAuthSignIn}>
                <img src={googleIcon} alt="google" />
              </button>
            </div>
            <div className="sign-in__line" />*/}
          </div>
        </div>
        <div className="auth__animation__container">
          <div className="auth__animation" />
        </div>
      </div>
    );
  }
}

export default withOAuth(CustomSignIn);
