import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import CalibrationCircle from './components/calibration-circle/CalibrationCircle';
import { setCvReady } from '../../store/actions/cv';
import { cvSelectors } from '../../store/selectors/cv';
import { exerciseSelectors } from '../../store/selectors/exerciseSelectors';
import './Calibration.scss';
import loadFloeSdk from '../../common/utils/loadFloeSdk';
import SdkApi from '../../common/utils/sdk_api';
import { calibrationEdgeValues, videoResolution } from '../../common/constants/data';
import ProgressDots from '../../common/components/progress-dots/ProgressDots';

class Calibration extends PureComponent {
  constructor() {
    super();

    this.calibrationSteps = [
      { // faceDetected
        checkFunction: (facePosition) => {
          const {
            height,
            width,
            x,
            y,
          } = facePosition;
          return height !== 0 && width !== 0 && x !== 0 && y !== 0;
        },
        errorState: {
          displayPrimaryInfo: 'No face detected',
          displaySecondaryInfo: 'Floe is making sure that it can see you',
          calibrationDone: false,
          indicatorFulfilled: 0,
        },
        successState: {
          indicatorFulfilled: 0,
        },
        nextTimeoutValue: 0,
      },
      { // faceNotTooBig
        checkFunction: (facePosition) => {
          const { height } = facePosition;
          const { faceSizeMax } = calibrationEdgeValues;
          return height < faceSizeMax;
        },
        errorState: {
          displayPrimaryInfo: 'Please move back from the screen',
          displaySecondaryInfo: 'Floe is making sure that it can see you',
          calibrationDone: false,
          indicatorFulfilled: 0,
        },
        successState: {
          indicatorFulfilled: 25,
        },
        nextTimeoutValue: 500,
      },
      { // faceNotTooSmall
        checkFunction: (facePosition) => {
          const { height } = facePosition;
          const { faceSizeMin } = calibrationEdgeValues;

          return height > faceSizeMin;
        },
        errorState: {
          displayPrimaryInfo: 'Move closer to the screen',
          displaySecondaryInfo: 'Floe is making sure that it can see you',
          calibrationDone: false,
          indicatorFulfilled: 25,
        },
        successState: {
          indicatorFulfilled: 50,
        },
        nextTimeoutValue: 500,
      },
      { // yPositionOk
        checkFunction: (facePosition) => {
          const { y } = facePosition;
          const { faceYMax } = calibrationEdgeValues;
          return y < faceYMax;
        },
        errorState: {
          displayPrimaryInfo: 'Please move your head up higher',
          displaySecondaryInfo: 'Floe is making sure that it can see you',
          calibrationDone: false,
          indicatorFulfilled: 50,
        },
        successState: {
          indicatorFulfilled: 75,
        },
        nextTimeoutValue: 500,
      },
      { // xPositionOk
        checkFunction: (facePosition) => {
          const { x, width } = facePosition;
          const { faceXMax } = calibrationEdgeValues;
          return Math.abs(x + width / 2 - videoResolution.width / 2) < faceXMax;
        },
        errorState: {
          displayPrimaryInfo: 'Please align your face with center of the screen',
          displaySecondaryInfo: 'Floe is making sure that it can see you',
          calibrationDone: false,
          indicatorFulfilled: 75,
        },
        successState: {
          indicatorFulfilled: 100,
        },
        nextTimeoutValue: 500,
      },
      { // perfect
        checkFunction: () => true,
        successState: {
          indicatorFulfilled: 100,
          calibrationDone: false,
          displayPrimaryInfo: 'Perfect',
          displaySecondaryInfo: 'Floe can see you',
        },
        nextTimeoutValue: 500,
      },
      { // perfect
        checkFunction: () => true,
        successState: {
          indicatorFulfilled: 100,
          calibrationDone: true,
          displayPrimaryInfo: 'Perfect',
          displaySecondaryInfo: 'Floe can see you',
        },
        nextTimeoutValue: 2500,
      },
    ];

    this.defaultState = {
      displayPrimaryInfo: 'Relax and sit still',
      displaySecondaryInfo: 'Floe is making sure that it can see you',
    };

    this.state = {
      calibrationDone: false,
      displayPrimaryInfo: 'Relax and sit still',
      displaySecondaryInfo: 'Floe is making sure that it can see you',
      indicatorFulfilled: 0,
      calibrationRunning: false,
      calibrationStepIndex: 0,
      calibrationReachedStepIndex: 0,
      calibrationTimeout: false,
    };

    this.waterfallCalibration = this.waterfallCalibration.bind(this);
    this.setCameraPending = this.setCameraPending.bind(this);
    this.startCalibration = this.startCalibration.bind(this);
    this.setDefualtInfo = this.setDefualtInfo.bind(this);
  }

  componentDidMount() {
    const {
      cvReady,
      setCvReadyAction,
      typeOfExercise,
      history,
    } = this.props;

    if (!typeOfExercise) {
      history.push('/');
    }

    if (cvReady) {
      this.startCalibration();
    } else {
      loadFloeSdk(setCvReadyAction);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { facePosition, cvReady, cameraPending, history } = this.props;
    const { calibrationRunning, calibrationTimeout, calibrationDone } = this.state;

    if (!calibrationRunning && cvReady) {
      this.startCalibration();
    }

    if (cameraPending && cameraPending !== prevProps.cameraPending) {
      this.setCameraPending();
    }

    if ((
      calibrationTimeout !== prevState.calibrationTimeout
      || facePosition !== prevProps.facePosition
    ) && !calibrationTimeout && facePosition) {
      this.waterfallCalibration();
    }
    if(calibrationDone) {
      setTimeout(function(){ history.push('/questions-pre-session'); }, 2000);
    }
  }

  componentWillUnmount() {
    const { history: { location: { pathname } } } = this.props;

    if (!(pathname === '/questions-pre-session')) {
      SdkApi.stop();
    }
  }

  // it is outside the waterfallCalibration becouse there is no face object yet
  setCameraPending() {
    this.setState({
      displayPrimaryInfo: 'Waiting for permissions',
      displaySecondaryInfo: 'Floe needs camera access to work',
      calibrationDone: false,
      indicatorFulfilled: 0,
    });
  }

  setDefualtInfo() {
    this.setState({
      displayPrimaryInfo: 'Relax and sit still',
      displaySecondaryInfo: 'Floe is making sure that it can see you',
    });
  }

  waterfallCalibration() {
    const {
      calibrationStepIndex,
      calibrationReachedStepIndex,
    } = this.state;
    const { facePosition } = this.props;
    const isLastStep = calibrationStepIndex === this.calibrationSteps.length - 1;

    if (this.calibrationSteps[calibrationStepIndex].checkFunction(facePosition)) {
      if (calibrationStepIndex < calibrationReachedStepIndex) {
        this.setState({
          calibrationStepIndex: isLastStep ? calibrationStepIndex : calibrationStepIndex + 1,
        });
      } else {
        this.setState({
          ...this.defaultState,
          ...this.calibrationSteps[calibrationStepIndex].successState,
          calibrationStepIndex: isLastStep ? 0 : calibrationStepIndex + 1,
          calibrationReachedStepIndex: isLastStep ? calibrationReachedStepIndex
            : calibrationStepIndex + 1,
          calibrationTimeout: true,
        });

        setTimeout(() => {
          this.setState({
            calibrationTimeout: false,
          });
        }, this.calibrationSteps[calibrationStepIndex].nextTimeoutValue);
      }
    } else if (calibrationStepIndex === calibrationReachedStepIndex) {
      this.setState({
        ...this.calibrationSteps[calibrationStepIndex].errorState,
        calibrationStepIndex: 0,
        calibrationDone: false,
      });
    } else {
      this.setState({
        calibrationReachedStepIndex: calibrationStepIndex,
        calibrationDone: false,
      });
    }
  }

  startCalibration() {
    const { calibrationRunning } = this.state;
    if (!calibrationRunning) {
      SdkApi.run();
      this.setState(() => ({ calibrationRunning: true }));
    }
  }

  render() {
    const {
      calibrationDone,
      indicatorFulfilled,
      displayPrimaryInfo,
      displaySecondaryInfo,
    } = this.state;
    const {
      cvReady,
    } = this.props;

    return (
      <div className="container calibration">
        <div className="circle-container">
          <CalibrationCircle
            shine={calibrationDone}
            percentFulfilled={indicatorFulfilled}
          />
        </div>
        <div className="content">
          <div className="info">
            <p className="info__primary">{displayPrimaryInfo}</p>
            <p className="info__secondary">{displaySecondaryInfo}</p>
          </div>
          <div className="buttons">
            {cvReady
              && (
                <div>
                  <Link
                    to="/instruction"
                  >
                    <button className="button button-calibration" type="button"> Back </button>
                  </Link>
                </div>
              )}
          </div>
        </div>
        <div className="progress-dots-container">
          <ProgressDots progressDotsNumber={2} />
        </div>
      </div>
    );
  }
}

Calibration.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  cvReady: PropTypes.bool.isRequired,
  cameraPending: PropTypes.bool.isRequired,
  setCvReadyAction: PropTypes.func.isRequired,
  typeOfExercise: PropTypes.string,
  facePosition: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
  }),
};

Calibration.defaultProps = {
  facePosition: null,
  typeOfExercise: null,
};

const mapStateToProps = (state) => ({
  cvReady: cvSelectors.getCvReady(state),
  cameraPending: cvSelectors.getCameraPending(state),
  facePosition: cvSelectors.getFacePosition(state),
  typeOfExercise: exerciseSelectors.getTypeOfExercise(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setCvReadyAction: setCvReady,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Calibration));
