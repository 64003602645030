const PREFIX = '[CV]';

export const SET_CV_READY = `${PREFIX} SET_CV_READY`;
export const ADD_RESP_INFO = `${PREFIX} ADD_RESP_INFO`;
export const RESET_RESP_INFO = `${PREFIX} RESET_RESP_INFO`;
export const SET_BREATHS = `${PREFIX} SET_BREATHS`;
export const RESET_BREATHS = `${PREFIX} RESET_BREATHS`;
export const SET_GUIDE_DATA = `${PREFIX} SET_GUIDE_DATA`;
export const SET_CAMERA_ON = `${PREFIX} SET_CAMERA_ON`;
export const SET_CAMERA_PENDING = `${PREFIX} SET_CAMERA_PENDING`;
export const SET_CAMERA_OFF = `${PREFIX} SET_CAMERA_OFF`;

export const setCvReady = () => ({
  type: SET_CV_READY,
});

export const addRespInfo = (respInfo) => ({
  type: ADD_RESP_INFO,
  respInfo,
});

export const resetRespInfo = () => ({
  type: RESET_RESP_INFO,
});

export const setBreaths = (breaths) => ({
  type: SET_BREATHS,
  breaths,
});

export const resetBreaths = () => ({
  type: RESET_BREATHS,
});

export const setGuideData = (guideData) => ({
  type: SET_GUIDE_DATA,
  guideData,
});

export const setCameraOn = () => ({
  type: SET_CAMERA_ON,
});

export const setCameraPending = () => ({
  type: SET_CAMERA_PENDING,
});

export const setCameraOff = () => ({
  type: SET_CAMERA_OFF,
});
