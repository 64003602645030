import React from 'react';
import { ForgotPassword } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';

import './CustomForgotPassword.scss';

class CustomForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this.state = {
      isSendView: true,
    };
    // eslint-disable-next-line no-underscore-dangle
    this._validAuthStates = ['forgotPassword'];
    this.handleSend = this.handleSend.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSend(event) {
    event.preventDefault();

    const {
      username,
    } = this.inputs;

    try {
      await Auth.forgotPassword(username);
      this.setState({ isSendView: false });
    } catch (err) {
      this.error(err);
    }
  }

  async handleSubmit() {
    const {
      username,
      password,
      code,
    } = this.inputs;

    if (password && password.length < 8) {
      return this.error('Password too short');
    }

    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      this.setState({ isSendView: true });
      return this.changeState('signIn');
    } catch (err) {
      return this.error(err);
    }
  }

  // eslint-disable-next-line no-unused-vars
  showComponent(themeIn) {
    const { isSendView } = this.state;

    return (
      <div className="auth">
        <div className="auth__content">
          <div className="forgot-password">
            <h3 className="forgot-password__headline">{ isSendView ? 'Forgot Password' : 'Submit new password'}</h3>
            <input
              className="forgot-password__input"
              key="username"
              name="username"
              onChange={this.handleInputChange}
              type="text"
              id="username"
              placeholder="Email"
            />
            {
            !isSendView
              && (
                <>
                  <input
                    className="forgot-password__input"
                    key="code"
                    name="code"
                    onChange={this.handleInputChange}
                    type="text"
                    id="code"
                    placeholder="code"
                  />
                  <input
                    className="forgot-password__input"
                    type="password"
                    key="password"
                    name="password"
                    onChange={this.handleInputChange}
                    id="password"
                    placeholder="Password"
                  />
                </>
              )
            }
            <button type="button" className="button" onClick={isSendView ? this.handleSend : this.handleSubmit}>
              Submit
            </button>
            <div className="forgot-password__links-container">
              <button
                type="button"
                className="forgot-password__text"
                onClick={() => this.changeState('signIn')}
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
        <div className="auth__image" />
      </div>
    );
  }
}

export default CustomForgotPassword;
