import {
  SET_TYPE,
  GET_QUESTIONS_REQUEST,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAILURE,
  SET_PRE_SESSION_ANSWERS,
  SET_POST_SESSION_ANSWERS,
  GET_GUIDES_REQUEST,
  GET_GUIDES_SUCCESS,
  GET_GUIDES_FAILURE,
  SET_ACTUAL_SESSION,
} from '../actions/exerciseActions';

const initialState = {
  type: '',
  guides: [],
  guidesFetching: false,
  questions: null,
  questionsFetching: false,
  preSessionAnswers: [],
  postSessionAnswers: [],
  actualSession: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case GET_QUESTIONS_REQUEST:
      return {
        ...state,
        questionsFetching: true,
      };
    case GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: action.questions,
        questionsFetching: false,
      };
    case GET_QUESTIONS_FAILURE:
      return {
        ...state,
        questionsFetching: false,
      };
    case SET_PRE_SESSION_ANSWERS:
      return {
        ...state,
        preSessionAnswers: action.answers,
      };
    case SET_POST_SESSION_ANSWERS:
      return {
        ...state,
        postSessionAnswers: action.answers,
      };
    case GET_GUIDES_REQUEST:
      return {
        ...state,
        guidesFetching: true,
      };
    case GET_GUIDES_SUCCESS:
      return {
        ...state,
        guides: action.guides,
        guidesFetching: false,
      };
    case GET_GUIDES_FAILURE:
      return {
        ...state,
        guidesFetching: false,
      };

    case SET_ACTUAL_SESSION:
      return {
        ...state,
        actualSession: true,
      };

    default:
      return state;
  }
};
