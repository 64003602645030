import React, { PureComponent } from 'react';
import './BreathDetection.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import CircleIndicator from '../../../../common/components/circle-indicator/CircleIndicator';
import { cvSelectors } from '../../../../store/selectors/cv';
import { exerciseSelectors } from '../../../../store/selectors/exerciseSelectors';
import { getGuides } from '../../../../store/actions/exerciseActions';
import BreathGuideIndicator from '../breath-guide-indicator/BreathGuideIndicator';
import BreathTimer from '../breath-timer/BreathTimer';
import { createGuideArray } from '../../../../common/utils/createGuideArray';
import { resetRespInfo, resetBreaths } from '../../../../store/actions/cv';
import SdkApi from '../../../../common/utils/sdk_api';

const audio = require.context('../../../../../static/audio', true);

class BreathDetector extends PureComponent {
  constructor() {
    super();

    this.state = {
      guideCurrentDuration: null,
      guideCurrentInfo: null,
      guideCurrentIntervalCount: 0,
      guideIteration: 0,
      timer: 0,
      guideArray: [],
      mouseMove: null,
      exerciseRunning: false,
      backgroundAudio: null,
    };

    this.mouseTimeout = false;
    this.runGuide = this.runGuide.bind(this);
    this.changeGuideInfo = this.changeGuideInfo.bind(this);
    this.getTime = this.getTime.bind(this);
    this.createGuideArray = this.createGuideArray.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.loadBackgroundAudio = this.loadBackgroundAudio.bind(this);
  }

  componentDidMount() {
    const {
      getGuidesAction,
      history,
      typeOfExercise,
      resetRespInfoAction,
      resetBreathsAction,
      cvReady,
      guide,
    } = this.props;

    if (!typeOfExercise) {
      history.push('/');
    } else {
      getGuidesAction();
      SdkApi.reset();
      resetRespInfoAction();
      resetBreathsAction();

      if (cvReady && guide) {
        this.createGuideArray(guide);
        this.runGuide();
      }
    }
  }

  componentWillUnmount() {
    const { backgroundAudio } = this.state;
    const { history: { location: { pathname } } } = this.props;
    clearInterval(this.guideInterval);
    SdkApi.calculateBreaths();
    if (!(pathname === '/questions-post-session' || pathname === '/questions-pre-session')) {
      SdkApi.stop();
    }

    if(backgroundAudio) {
      backgroundAudio.pause();
      backgroundAudio.currentTime = 0;
    }
  }

  onMouseMove(event) {
    if (!this.mouseTimeout) {
      const { screenX, screenY } = event;
      this.setState({ mouseMove: { screenX, screenY } });
      this.mouseTimeout = true;
      setTimeout(() => { this.mouseTimeout = false; }, 300);
    }
  }

  getTime() {
    const { timer } = this.state;
    return `${Math.floor(timer / 60)}:${timer % 60 < 10 ? '0' : ''}${timer % 60}`;
  }

  changeGuideInfo() {
    this.setState((state) => ({
      guideCurrentIntervalCount: state.guideCurrentIntervalCount + 1,
      timer: state.timer - 1,
    }), () => {
      const {
        guideCurrentIntervalCount,
        guideArray,
        guideIteration,
        timer,
      } = this.state;

      const {
        history,
      } = this.props;

      if (timer === 0) {
        history.push('/questions-post-session');
      }

      if (guideCurrentIntervalCount === guideArray[guideIteration].duration && timer > 0) {
        this.setState((state) => ({
          guideCurrentInfo: state.guideArray[state.guideIteration + 1].name,
          guideCurrentDuration: state.guideArray[state.guideIteration + 1].duration,
          guideIteration: state.guideIteration + 1,
          guideCurrentIntervalCount: 0,
        }));

        if(guideArray[guideIteration + 1].audio) {
            guideArray[guideIteration + 1].audio.play();
        }
      }
    });
  }

  runGuide() {
    const { guide: { maxTime } } = this.props;

    this.loadBackgroundAudio();

    this.setState((state) => ({
      exerciseRunning: true,
      guideCurrentInfo: state.guideArray[state.guideIteration].name,
      guideCurrentDuration: state.guideArray[state.guideIteration].duration,
      timer: maxTime,
    }), () => {
      const {
        guideArray,
        guideIteration,
      } = this.state;

      if(guideArray[guideIteration].audio) {
        guideArray[guideIteration].audio.play();
      }

    });


    this.guideInterval = setInterval(() => { this.changeGuideInfo(); }, 1000);
  }

  createGuideArray(guide) {
    this.setState(() => ({ guideArray: createGuideArray(guide) }));
  }

  loadBackgroundAudio() {
    const { guide: { backgroundAudioFileName } } = this.props;

    if(backgroundAudioFileName) {

      try {

        let audioFile = new Audio(audio('./' + backgroundAudioFileName));

        if(audioFile) {
          audioFile.volume = 0.2;
          audioFile.play();

          this.setState((state) => ({
            backgroundAudio: audioFile,
          }));
        }

      } catch (error) {
         console.log(error);
      }
    }
  
  }

  render() {
    const {
      graphHeight,
      diffFromCenterX,
    } = this.props;

    const {
      guideCurrentDuration,
      guideCurrentInfo,
      mouseMove,
      exerciseRunning,
    } = this.state;

    return (
      <div className="breath-detection" onMouseMove={this.onMouseMove}>
        <div className="circle-container">
          <CircleIndicator graphHeight={graphHeight} diffFromCenterX={diffFromCenterX} />
        </div>
        <div className="breath-detection-guide">
          {exerciseRunning
            && <BreathGuideIndicator info={guideCurrentInfo} duration={guideCurrentDuration} />}
          {exerciseRunning
            && <BreathTimer time={this.getTime()} triggerAnimation={mouseMove} />}
        </div>
      </div>
    );
  }
}

BreathDetector.propTypes = {
  cvReady: PropTypes.bool.isRequired,
  graphHeight: PropTypes.number.isRequired,
  diffFromCenterX: PropTypes.number.isRequired,
  resetRespInfoAction: PropTypes.func.isRequired,
  getGuidesAction: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  typeOfExercise: PropTypes.string,
  guide: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    maxTime: PropTypes.number,
    backgroundAudioFileName: PropTypes.string,
    exerciseLoop: PropTypes.arrayOf(PropTypes.shape({
      stepIndex: PropTypes.number,
      displayName: PropTypes.string,
      duration: PropTypes.number,
      scaleFactor: PropTypes.number,
      increment: PropTypes.number,
      initialGraphHeight: PropTypes.number,
      targetGraphHeight: PropTypes.number,
    })),
  }),
  resetBreathsAction: PropTypes.func.isRequired,
};

BreathDetector.defaultProps = {
  guide: null,
  typeOfExercise: null,
};

const mapStateToProps = (state) => ({
  cvReady: cvSelectors.getCvReady(state),
  graphHeight: cvSelectors.getLastGraphHeight(state),
  diffFromCenterX: cvSelectors.getDiffFromCenterX(state),
  cameraRunning: cvSelectors.getCameraRunning(state),
  typeOfExercise: exerciseSelectors.getTypeOfExercise(state),
  guide: exerciseSelectors.getGuide(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetRespInfoAction: resetRespInfo,
  getGuidesAction: getGuides,
  resetBreathsAction: resetBreaths,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BreathDetector));
