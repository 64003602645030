import { videoResolution } from '../../common/constants/data';
import { getAvgPosXOfLast } from '../../common/utils/getAverageOfLast';
// eslint-disable-next-line import/prefer-default-export
export const cvSelectors = {
  getCameraRunning: (state) => state.cv.cameraRunning,
  getCameraPending: (state) => state.cv.cameraPending,
  getCvReady: (state) => state.cv.cvReady,
  getLastGraphHeight: (state) => (state.cv.respInfo.length > 0
    ? state.cv.respInfo[state.cv.respInfo.length - 1].graphHeight
    : null),
  getLastPosX: (state) => {
    const respInfo = [...state.cv.respInfo];

    if (respInfo.length > 0) {
      const avg = getAvgPosXOfLast(10, respInfo, 'x');

      if (avg) {
        return avg;
      }
      const reversedRespInfo = respInfo.reverse();
      const lastNonZeroEl = reversedRespInfo.find((el) => el.x !== 0);

      if (lastNonZeroEl) {
        return lastNonZeroEl.x;
      }
    }

    return videoResolution.width / 2; // middle of the screen
  },
  getDiffFromCenterX: (state) => (videoResolution.width / 2 - cvSelectors.getLastPosX(state)),
  getFacePosition: (state) => (state.cv.respInfo.length > 0
    ? state.cv.respInfo[state.cv.respInfo.length - 1].face
    : null),
  getRespInfo: (state) => state.cv.respInfo,
  getBreaths: (state) => state.cv.breaths,
};
