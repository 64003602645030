import React from 'react';
import './ProgressDots.scss';
import PropTypes from 'prop-types';

const ProgressDots = (props) => {
  const { progressDotsNumber } = props;

  const drawDots = () => {
    const dots = [];

    for (let i = 0; i < 4; i += 1) {
      dots.push(<div key={i} className={progressDotsNumber >= i + 1 ? 'progress-dots__dot progress-dots__dot--active' : 'progress-dots__dot'} />);
    }
    return dots;
  };

  return (
    <div className="progress-dots">
      {drawDots()}
    </div>
  );
};

ProgressDots.propTypes = {
  progressDotsNumber: PropTypes.number,
};

ProgressDots.defaultProps = {
  progressDotsNumber: 0,
};

export default ProgressDots;
