import React, { useState, useEffect } from 'react';
import './QuestionForm.scss';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

const StyledLabel = withStyles({
  label: {
    'font-size': '12px',
    'font-family': 'Inter',
    'font-weight': 500,
    'line-height': '12px',
  },
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props) => <FormControlLabel color="default" {...props} />);

const StyledRadio = withStyles({
  root: {
    color: '#393D3C',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props) => <Radio color="default" {...props} />);

const QuestionForm = (props) => {
  const [answer, setAnswer] = useState('');
  const {
    questionObj: { question, options, questionUUID },
    backFn,
    nextFn,
    backButtonDisabled,
    nextButtonDisabled,
  } = props;

  useEffect(() => {
    setAnswer('');
  }, [questionUUID]);


  const handleChange = (event) => {
    setAnswer(event.target.value);
  };

  const drawForm = (answers) => (
    <FormControl component="fieldset">
      <RadioGroup
        className="question-form__radios"
        onChange={handleChange}
        value={answer}
        row
      >
        {
          answers.map((item) => (
            <StyledLabel
              className="question-form__label"
              value={item.index.toString()}
              control={(
                <StyledRadio
                  disableRipple
                  icon={<RadioButtonUncheckedIcon fontSize="small" />}
                  checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                />
              )}
              label={item.displayText}
              key={item.index}
            />
          ))
        }
      </RadioGroup>
    </FormControl>
  );

  return (
    <div className="question-form">
      <p className="question-form__question">{question}</p>
      {drawForm(options)}
      <div className="question-form__buttons">
        <button
          type="button"
          className={backButtonDisabled ? 'button button--disabled' : 'button'}
          onClick={backFn}
          disabled={backButtonDisabled}
        >Back
        </button>
        <button
          type="button"
          className={(answer || nextButtonDisabled) ? 'button' : 'button button--disabled'}
          disabled={(!answer || nextButtonDisabled)}
          onClick={() => nextFn({ questionUUID, answer })}
        >Next
        </button>
      </div>
    </div>
  );
};

QuestionForm.propTypes = {
  questionObj: PropTypes.shape({
    questionUUID: PropTypes.string,
    question: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        index: PropTypes.number,
        displayText: PropTypes.string,
      }),
    ),
  }),
  backFn: PropTypes.func.isRequired,
  nextFn: PropTypes.func.isRequired,
  backButtonDisabled: PropTypes.bool,
  nextButtonDisabled: PropTypes.bool,
};

QuestionForm.defaultProps = {
  questionObj: null,
  backButtonDisabled: false,
  nextButtonDisabled: false,
};

export default QuestionForm;
