const PREFIX = '[USER]';
export const USER_LOGOUT_ERROR = `${PREFIX} USER_LOGOUT_ERROR`;
export const USER_SETTINGS_ERROR = `${PREFIX} USER_SETTING_ERROR`;
export const USER_ERROR_CLEAR = `${PREFIX} USER_ERROR_CLEAR`;

export const userLogoutFailure = (payload) => ({
  type: USER_LOGOUT_ERROR,
  payload,
});

export const userSettingsFailure = (payload) => ({
  type: USER_SETTINGS_ERROR,
  payload,
});

export const userErrorClear = () => ({
  type: USER_ERROR_CLEAR,
});
