import React from 'react';
import PropTypes from 'prop-types';
import womanImg from 'static/images/woman.svg';
import tableImg from 'static/images/table.svg';
import './StepTwo.scss';

const StepTwo = (props) => {
  const { skipInstructions } = props;

  return (
    <div className="step-two">
      <div className="step-two-images">
        <img className="step-two-images__woman" src={womanImg} alt="woman on a chair" />
        <img className="step-two-images__table" src={tableImg} alt="table" />
      </div>
      <div className="step-two-text">
        <strong className="step-two-text--primary"><p>Keep the laptop still</p></strong>
        <p className="step-two-text--secondary">
          It’s important that your camera remains still to track your movement,
           we recommend placing your laptop on a desk.
        </p>
      </div>
      <div className="step-two-buttons">
        <button className="button step-two-buttons__skip" type="button" onClick={skipInstructions}> Skip</button>
      </div>
    </div>
  );
};

StepTwo.propTypes = {
  skipInstructions: PropTypes.func.isRequired,
};

export default StepTwo;
