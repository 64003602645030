/* eslint-disable import/prefer-default-export */
const audio = require.context('../../../static/audio', true);
export function createGuideArray(guideObject, exerciseTime) {
  const { maxTime, exerciseLoop } = guideObject;
  const results = [];
  const endTime = exerciseTime || maxTime;
  let currentTime = 0;
  let loopIteration = 0;

  while (currentTime < endTime) {
    for (let i = 0; i < exerciseLoop.length; i += 1) {
      const {
        stepIndex,
        displayName,
        duration,
        scaleFactor,
        increment,
        targetGraphHeight,
        maxDuration,
        audioFileName,
      } = exerciseLoop[i];

      let resultDuration;

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(duration)) {
        // eslint-disable-next-line no-shadow
        const { stepIndex, scaleFactor, increment } = duration;
        const referenceStep = exerciseLoop.find((el) => el.stepIndex === stepIndex);
        const durationInput = referenceStep.duration * referenceStep.scaleFactor + referenceStep.increment;
        resultDuration = scaleFactor ** loopIteration * durationInput + increment * loopIteration;
      } else {
        resultDuration = scaleFactor ** loopIteration * duration + increment * loopIteration;
        resultDuration = maxDuration && maxDuration < resultDuration ? maxDuration : resultDuration;
      }

      let audioFile = null;
      if(audioFileName) {
        try {

          audioFile = new Audio(audio('./' + audioFileName));

        } catch (error) {
          console.log(error);
        }
      }

      results.push({
        name: displayName,
        index: stepIndex,
        duration: resultDuration,
        time: currentTime + resultDuration,
        graphHeight: targetGraphHeight,
        audio: audioFile,
      });

      currentTime += resultDuration;
    }

    loopIteration += 1;
  }

  return results;
}
