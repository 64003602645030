
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import focusIcon from 'static/icons/icon_header_focus.svg';
import calmIcon from 'static/icons/icon_header_calm.svg';
import creativityIcon from 'static/icons/icon_header_creativity.svg';
import endIcon from 'static/icons/icon_header_end.svg';
import './HeaderExercise.scss';


function HeaderExercise({
  typeOfExercise,
  path,
  isCameraRunning,
  onEndSessionClick,
}) {
  let icon = '';

  switch (typeOfExercise) {
    case 'Creativity':
      icon = creativityIcon;
      break;
    case 'Calm':
      icon = calmIcon;
      break;
    default:
      icon = focusIcon;
  }

  const postSession = path === '/questions-post-session';

  return (
    <div className="header-exercise">
      <div className="header-exercise__left-container">
        <img src={icon} alt="" />
        <span className="header-exercise__heading">{typeOfExercise}</span>
      </div>
      <div className="container header-exercise__camera-container">
        {
          path !== '/instruction'
          && (
            <div className="header-exercise__camera">
              <span
                className={classNames('header-exercise__camera-sign', {
                  'header-exercise__camera-sign--camera-running': isCameraRunning,
                })}
              />
              <span>Camera {isCameraRunning ? 'on' : 'off'}</span>
            </div>
          )
        }
      </div>
      <div className="header-exercise__right-container">
        <button type="button" onClick={onEndSessionClick} disabled={postSession}>
          <img src={endIcon} alt="" className={postSession ? 'button--disabled' : null} />
          <span className={classNames('header-exercise__right-text', { 'button--disabled': postSession })}>End Session</span>
        </button>
      </div>
    </div>
  );
}

HeaderExercise.propTypes = {
  typeOfExercise: PropTypes.string.isRequired,
  isCameraRunning: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  onEndSessionClick: PropTypes.func.isRequired,
};

export default HeaderExercise;
