import {
  SET_CV_READY,
  ADD_RESP_INFO,
  RESET_RESP_INFO,
  SET_BREATHS,
  RESET_BREATHS,
  SET_GUIDE_DATA,
  SET_CAMERA_ON,
  SET_CAMERA_PENDING,
  SET_CAMERA_OFF,
} from '../actions/cv';

const initialState = {
  cvReady: false,
  respInfo: [],
  breaths: [],
  guideData: [],
  cameraPending: false,
  cameraRunning: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CV_READY:
      return {
        ...state,
        cvReady: true,
      };
    case ADD_RESP_INFO:
      return {
        ...state,
        respInfo: [...state.respInfo, action.respInfo],
      };
    case RESET_RESP_INFO:
      return {
        ...state,
        respInfo: [],
      };
    case SET_BREATHS:
      return {
        ...state,
        breaths: action.breaths,
      };
    case RESET_BREATHS:
      return {
        ...state,
        breaths: [],
      };
    case SET_GUIDE_DATA:
      return {
        ...state,
        guideData: action.guideData,
      };
    case SET_CAMERA_ON:
      return {
        ...state,
        cameraPending: false,
        cameraRunning: true,
      };
    case SET_CAMERA_PENDING:
      return {
        ...state,
        cameraPending: true,
      };
    case SET_CAMERA_OFF:
      return {
        ...state,
        cameraRunning: false,
      };
    default:
      return state;
  }
};
