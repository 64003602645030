/* eslint-disable import/prefer-default-export */
export const exerciseSelectors = {
  getTypeOfExercise: (state) => state.exercise.type,
  getPreSessionQuestions: (state) => (state.exercise.questions ? state.exercise.questions['pre-exercise-questions'].questionnaire : null),
  getPostSessionQuestions: (state) => (state.exercise.questions ? state.exercise.questions['post-exercise-questions'].questionnaire : null),
  getGuides: (state) => state.exercise.guides,
  getGuide: (state) => {
    if (state.exercise.type && state.exercise.guides) {
      return state.exercise.guides.find((el) => el.category === state.exercise.type);
    }
    return null;
  },
  getPreSessionAnswers: (state) => state.exercise.preSessionAnswers,
  getPostSessionAnswers: (state) => state.exercise.postSessionAnswers,
  getIsActualSession: (state) => state.exercise.actualSession,
};
