import {
  USER_LOGOUT_ERROR,
  USER_SETTINGS_ERROR,
  USER_ERROR_CLEAR,
} from '../actions/userActions';

const initialState = {
  data: [],
  errors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_SETTINGS_ERROR:
    case USER_LOGOUT_ERROR:
      return {
        ...state,
        errors: action.payload,
      };

    case USER_ERROR_CLEAR:
      return {
        ...state,
        errors: [],
      };

    default:
      return state;
  }
};
